import { QuiBox, QuiCheckboxField } from '@tonicai/ui-quinine';
import { useEffect } from 'react';
import { useField, useForm, useFormState } from 'react-final-form';
import { GeneratorMetadataProps } from '../../types';

export function NameGeneratorMetadataField({ name, automaticallySubmit }: GeneratorMetadataProps) {
    const isConsistencyCaseSensitive = useField(`${name}.isConsistencyCaseSensitive`, { subscription: { value: true } });

    const form = useForm();
    const formState = useFormState({ subscription: { submitting: true } });

    useEffect(() => {
        if (automaticallySubmit) {
            form.submit();
        }
    }, [isConsistencyCaseSensitive.input.value, form, automaticallySubmit]);

    return (
        <QuiBox display="flex" flexDirection="column" gap="sm">
            <QuiBox borderRadius="md" border="white-600" text="text-xs">
                <QuiBox display="flex" flexDirection="column" borderRadius="md" padding={'md'} gap={'md'}>
                    <QuiCheckboxField
                        name={`${name}.isConsistencyCaseSensitive`}
                        label={'Is Consistency Case Sensitive'}
                        helperText={'When enabled, the same input name with different casing will produce different outputs.'}
                        disabled={formState.submitting}
                    />
                </QuiBox>
            </QuiBox>
        </QuiBox>
    );
}
