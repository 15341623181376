import { CopyToClipboardButton } from '../CopyToClipboardButton/CopyToClipboardButton';
import { QuiBox, QuiSpinner } from '@tonicai/ui-quinine';
import { CodeBlock, CodeBlockProps } from './CodeBlock';

export type CodeBoxProps = Readonly<
    {
        title: string;
        loading?: boolean;
        disableCopy?: boolean;
    } & CodeBlockProps
>;

export function CodeBox({ title, loading, disableCopy, ...codeBlockProps }: CodeBoxProps) {
    return (
        <QuiBox bg="background-primary" border="border-default" borderRadius="md">
            <QuiBox borderBottom="border-default" padding="sm" text="mono-text-xs" display={'flex'} justifyContent={'space-between'}>
                <QuiBox display="flex" gap="sm" alignItems="center">
                    {loading ? <QuiSpinner size="xs" /> : null}
                    {title}
                </QuiBox>
                {disableCopy !== true ? (
                    <CopyToClipboardButton size="xs" variant="minimal" icon="copy" content={codeBlockProps.code}>
                        Copy
                    </CopyToClipboardButton>
                ) : null}
            </QuiBox>
            <CodeBlock {...codeBlockProps} />
        </QuiBox>
    );
}
