import { ApiKeys } from './ApiKeys';
import { Welcome } from './Welcome';
import { PythonSnippets } from './PythonSnippets';
import { datasetsFetchStatusAtom } from '../../stores/datasets';
import { useState } from 'react';
import { ApiKeyModal } from '../../components/ApiKeyModal/ApiKeyModal';
import { useAtom, useAtomValue } from 'jotai';
import { QuiBox, QuiFlexBoxColumn } from '@tonicai/ui-quinine';
import { parseJobsStatusAtom } from '../../stores/parse-jobs';
import styles from './Home.module.scss';
import { CreateDatasetDialog } from '../../components/CreateDatasetDialog/CreateDatasetDialog';
import { HeaderCard } from './HeaderCard';
import { WordCountBox } from '../Billing/WordCountBox';
import { CreatePipelineDialog } from '../../components/CreatePipelineDialog/CreatePipelineDialog';
import { HelpDocumentation } from '@/components/HelpDocumentation/HelpDocumentation';
import { openPlanDialog, useFreeTrialActive, useIsHostedProd, useLifetimeUsage } from '@/stores';
import { FreeTrialDialog } from './FreeTrialDialog';
import { atomWithStorage } from 'jotai/utils';
import { PipelinesDatasetsTable } from '@/components/PipelinesDatasetsTable/PipelinesDatasetsTable';

const closedFreeTrialModalAtom = atomWithStorage('closedFreeTrialModal', false);

export function Home() {
    const [hasClosedModal, setHasClosedModal] = useAtom(closedFreeTrialModalAtom);
    const lifetimeUsage = useLifetimeUsage();
    const freeTrialActive = useFreeTrialActive();
    const isHostedProd = useIsHostedProd();

    const showFreeTrialDialog = isHostedProd && !hasClosedModal && freeTrialActive && lifetimeUsage === 0;

    const datasetsStatus = useAtomValue(datasetsFetchStatusAtom);
    const parseJobsStatus = useAtomValue(parseJobsStatusAtom);

    const [activeModal, setActiveModal] = useState<null | 'apikey' | 'dataset' | 'parsejob' | 'learn-more'>(null);
    const openParseJobModal = () => {
        setActiveModal('parsejob');
    };
    const openApiKeyModal = () => {
        setActiveModal('apikey');
    };
    const closeModal = () => {
        setActiveModal(null);
        setHasClosedModal(true);
    };

    return (
        <>
            <div data-test="home-page" className={styles.container}>
                <div>
                    <QuiFlexBoxColumn style={{ minHeight: 'calc(100vh - 44px - 2rem)' }} gap="lg" justifyContent="space-between">
                        <Welcome />
                        <QuiFlexBoxColumn gap="md">
                            <WordCountBox />
                            <HelpDocumentation />
                        </QuiFlexBoxColumn>
                    </QuiFlexBoxColumn>
                </div>
                <div>
                    <QuiBox flexDirection="column" display="flex" gap="md">
                        <HeaderCard openCreatePipelineDialog={openParseJobModal} />

                        {datasetsStatus === 'success' && parseJobsStatus === 'success' ? <PipelinesDatasetsTable /> : null}
                    </QuiBox>
                </div>
                <div>
                    <ApiKeys openApiKeyModal={openApiKeyModal} />
                    <PythonSnippets />
                </div>
            </div>
            <ApiKeyModal isOpen={!showFreeTrialDialog && activeModal === 'apikey'} onClose={closeModal} />
            <CreateDatasetDialog isOpen={!showFreeTrialDialog && activeModal === 'dataset'} onClose={closeModal} />
            <CreatePipelineDialog isOpen={!showFreeTrialDialog && activeModal === 'parsejob'} onClose={closeModal} />
            <FreeTrialDialog
                isOpen={showFreeTrialDialog}
                onClose={closeModal}
                onLearnMore={() => {
                    closeModal();
                    openPlanDialog();
                }}
            />
        </>
    );
}
