import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';

dayjs.extend(advancedFormat);

export function formatBillingDate(date: Date) {
    return dayjs(date).format('MM/DD/YYYY');
}

const currencyFormat = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' });

export function formatCurrency(currency: number) {
    return currencyFormat.format(currency / 100);
}

const wordCountFormatter = Intl.NumberFormat('en', { notation: 'compact' });

export function formatWordCount(wordCount: number) {
    if (wordCount < 1000) {
        return wordCount.toString();
    }
    return wordCountFormatter.format(wordCount);
}
