import { QuiBox, QuiButton, QuiPopover, QuiText } from '@tonicai/ui-quinine';
import { UserApiKey } from '../../types';
import { useState } from 'react';
import { revokeApiKey } from '../../stores/userAPIKeys';
import { instrumentation } from '../../instrumentation/instrumentation';
import { useIsMounted } from '../../hooks/useIsMounted';
import dayjs from 'dayjs';

export function APIKey({ apiKey }: Readonly<{ apiKey: UserApiKey }>) {
    const [showDeletePopover, setShowDeletePopover] = useState(false);
    const [keyDescriptionPopoverOpen, setKeyDescriptionPopoverOpen] = useState(false);
    const isMounted = useIsMounted();

    return (
        <QuiBox
            bg="white-100"
            borderRadius="md"
            padding="sm"
            display="flex"
            flexDirection="row"
            gap="xs"
            justifyContent="space-between"
            alignItems="center"
        >
            <QuiBox display="flex" flexDirection="column" flexGrow="1" overflow="hidden">
                <QuiBox text="text-sm" textOverflow="ellipsis" whiteSpace="nowrap" overflow="hidden">
                    {apiKey.name}
                </QuiBox>
                <QuiBox text="text-xs" color="black-600" textOverflow="ellipsis" whiteSpace="nowrap" overflow="hidden">
                    {`Created ${dayjs(apiKey.createdDate).format('MMM D, YYYY')}`}
                </QuiBox>
            </QuiBox>
            <div style={{ display: 'flex', flexDirection: 'row', gap: 8 }}>
                <QuiPopover
                    variant={'black'}
                    content={'Key ends with "' + apiKey.key.slice(-4) + '"'}
                    isOpen={keyDescriptionPopoverOpen}
                    onClose={() => setKeyDescriptionPopoverOpen(false)}
                >
                    <QuiButton onClick={() => setKeyDescriptionPopoverOpen(true)} iconLeft={'key'} />
                </QuiPopover>
                <QuiPopover
                    isOpen={showDeletePopover}
                    onClose={() => setShowDeletePopover(false)}
                    placement="bottom-end"
                    content={
                        <QuiBox display="flex" flexDirection="column" gap="sm" padding="md">
                            <QuiText size="text-sm">Are you sure you want to revoke this key?</QuiText>
                            <QuiBox display="flex" gap="sm" justifyContent="end">
                                <QuiButton
                                    size="sm"
                                    onClick={() => {
                                        setShowDeletePopover(false);
                                    }}
                                >
                                    Cancel
                                </QuiButton>
                                <QuiButton
                                    size="sm"
                                    variant="destructive"
                                    onClick={() => {
                                        revokeApiKey(apiKey.id).then(() => {
                                            try {
                                                instrumentation.revokeAPIKey(apiKey.id);
                                            } catch {
                                                // ignore
                                            }

                                            if (isMounted()) {
                                                setShowDeletePopover(false);
                                            }
                                        });
                                    }}
                                >
                                    Revoke
                                </QuiButton>
                            </QuiBox>
                        </QuiBox>
                    }
                >
                    <QuiButton
                        onClick={() => {
                            setShowDeletePopover(true);
                        }}
                        iconLeft={'trash-2'}
                    />
                </QuiPopover>
            </div>
        </QuiBox>
    );
}
