import { QuiBox, QuiIcon, QuiIconToken } from '@tonicai/ui-quinine';
import { ReactNode } from 'react';
import { useMatch, useParams } from 'react-router-dom';
import { PlainLink } from '../PlainLink/PlainLink';
import { usePipelineContext } from './usePipelineContext';

function BreadcrumbDelimiter() {
    return (
        <QuiBox display="inline-flex" text="text-xs" color="text-disabled">
            <QuiIcon icon="chevron-right" />
        </QuiBox>
    );
}

type BreadcrumbButtonProps = Readonly<{
    icon: QuiIconToken;
    children: ReactNode;
    to: string;
    withDelimiter?: boolean;
}>;

function BreadcrumbButton({ icon, children, to, withDelimiter, ...props }: BreadcrumbButtonProps) {
    return (
        <>
            {withDelimiter ? <BreadcrumbDelimiter /> : null}

            <PlainLink to={to} {...props}>
                <QuiBox alignItems="center" gap="xs" display="inline-flex">
                    <QuiIcon icon={icon} />
                    <span>{children}</span>
                </QuiBox>
            </PlainLink>
        </>
    );
}

type PipelineBreadcrumbsParams = {
    parseJobConfigId: string;
    fileParseJobId: string;
    fileParseResultId: string;
};

export function PipelineBreadcrumbs() {
    const { parseJobConfig } = usePipelineContext();

    const { parseJobConfigId, fileParseJobId, fileParseResultId } = useParams<PipelineBreadcrumbsParams>();

    const matchSettings = useMatch(`/pipelines/:parseJobConfigId/settings`);

    if (!parseJobConfigId) return null;

    if (!matchSettings && !fileParseJobId) return null;

    if (parseJobConfig.useInternalBucket) {
        if (!fileParseJobId && !fileParseResultId) {
            return null;
        }
        return (
            <QuiBox padding="md none none md">
                <QuiBox color="text-secondary" text="text-xs" display="flex" alignItems="center" gap="xs">
                    <BreadcrumbButton to={`/pipelines/${parseJobConfigId}`} icon="arrow-left">
                        Back to Files
                    </BreadcrumbButton>
                </QuiBox>
            </QuiBox>
        );
    }

    return (
        <QuiBox padding="md none none md">
            <QuiBox color="text-secondary" text="text-xs" display="flex" alignItems="center" gap="xs">
                {matchSettings || fileParseJobId ? (
                    <BreadcrumbButton data-test="overview-breadcrumb" to={`/pipelines/${parseJobConfigId}`} icon="file-text">
                        Overview
                    </BreadcrumbButton>
                ) : null}

                {matchSettings ? (
                    <BreadcrumbButton withDelimiter to={`/pipelines/${parseJobConfigId}/settings`} icon="settings">
                        Settings
                    </BreadcrumbButton>
                ) : null}

                {fileParseJobId ? (
                    <BreadcrumbButton withDelimiter to={`/pipelines/${parseJobConfigId}/runs/${fileParseJobId}`} icon="file-text">
                        Run
                    </BreadcrumbButton>
                ) : null}

                {fileParseResultId ? (
                    <BreadcrumbButton
                        withDelimiter
                        to={`/pipelines/${parseJobConfigId}/runs/${fileParseJobId}/files/${fileParseResultId}`}
                        icon="file-text"
                    >
                        File
                    </BreadcrumbButton>
                ) : null}
            </QuiBox>
        </QuiBox>
    );
}
