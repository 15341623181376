import { createContext, useCallback, useContext, useMemo, useState } from 'react';
import { Breadcrumbs } from './Breadcrumbs';
import { LevelItems } from './LevelItems';
import { CloudStorageItem } from './types';

export type FileSystemType = 'aws' | 'databricks';

type CloudFileExplorerContextValue = CloudFileExplorerProps & {
    currentPath: string | null;
    goHome: () => void;
    goToLevel: (level: number) => void;
    selectItem: (item: string) => void;
    pathSegments: string[];
    levels: string[];
    currentLevel: number;
};

const CloudFileExplorerContext = createContext<CloudFileExplorerContextValue>({} as CloudFileExplorerContextValue);

export function useCloudFileExplorer() {
    return useContext(CloudFileExplorerContext);
}

export type KeyResponseModel = {
    keys: CloudStorageItem[];
    continuationToken?: string;
    isTruncated: boolean;
    maxKeys: number;
};

type CloudFileExplorerProps = Readonly<{
    allowFileSelection: boolean;
    allowMultipleSelection: boolean;
    disableNestedPaths: boolean; // If true, any paths that are nested within the selected paths will be disabled
    onFileSelected: (file: string) => void;
    onPathSelected: (path: string) => void;
    parseJobConfigId: string;
    pathPrefixes: string[];
    selectedFiles: string[];
    fileSystemType: FileSystemType;
}>;

export function CloudFileExplorer(props: CloudFileExplorerProps): JSX.Element {
    const [currentLevel, setCurrentLevel] = useState(0);
    const [pathSegments, setPathSegments] = useState<string[]>([]);
    const { fileSystemType } = props;
    const levels = (() => {
        switch (fileSystemType) {
            case 'aws':
                return ['bucket', 'path'];
            case 'databricks':
                return ['catalog', 'schema', 'volume', 'path'];
            default:
                throw new Error('Unsupported ObjectStorageType.');
        }
    })();

    const currentPath = useMemo(() => pathSegments.join('/'), [pathSegments]);

    const goHome = useCallback(() => {
        setCurrentLevel(0);
        setPathSegments([]);
    }, []);

    const goToLevel = useCallback((level: number) => {
        setCurrentLevel(level);
        setPathSegments((prev) => prev.slice(0, level));
    }, []);

    const selectItem = useCallback((item: string) => {
        setPathSegments((prev) => [...prev, item]);
        setCurrentLevel((prev) => prev + 1);
    }, []);

    const contextValue = useMemo<CloudFileExplorerContextValue>(() => {
        return {
            ...props,
            currentLevel,
            levels,
            pathSegments,
            currentPath,
            goHome,
            goToLevel,
            selectItem,
        };
    }, [props, currentLevel, levels, pathSegments, currentPath, goHome, goToLevel, selectItem]);

    return (
        <CloudFileExplorerContext.Provider value={contextValue}>
            <Breadcrumbs />
            <LevelItems />
        </CloudFileExplorerContext.Provider>
    );
}
