import { useCallback, useEffect, useMemo } from 'react';
import { Form } from 'react-final-form';
import mutators from 'final-form-arrays';
import { fetchDataset, updateDataset } from '../../stores/datasets';
import { GeneratorSetup } from './GeneratorSetup';
import { DatasetInfo } from './DatasetInfo';
import { DatasetMeta } from './DatasetMeta';
import { DatasetFormState } from '../../types';
import styles from './Dataset.module.scss';
import { Mutator } from 'final-form';
import dayjs from 'dayjs';
import { FilePreviewProvider } from '../../components/FilePreviews/FilePreviewProvider';
import { FilePreviewDialog } from '../../components/FilePreviews/FilePreviewDialog';
import { useDatasetContext } from '../../contexts/DatasetContext';

export function Dataset() {
    const { dataset } = useDatasetContext();
    const initialValues = useMemo<DatasetFormState>(() => {
        return {
            id: dataset.id,
            fileIds: [...dataset.files]
                .sort((a, b) => {
                    const diff = dayjs(a.uploadedTimestamp).diff(dayjs(b.uploadedTimestamp));
                    if (diff === 0) {
                        return a.fileName > b.fileName ? 1 : 0;
                    }
                    return diff;
                })
                .map((f) => f.fileId),
            name: dataset.name,
            generatorSetup: dataset.generatorSetup,
            labelBlockLists: dataset.labelBlockLists,
            enabledModels: dataset.enabledModels,
            datasetGeneratorMetadata: dataset.datasetGeneratorMetadata,
        };
    }, [dataset]);

    const handleSubmit = useCallback(async (values: DatasetFormState) => {
        await updateDataset(values);
    }, []);

    useEffect(() => {
        fetchDataset(dataset.id);
    }, [dataset.id]);

    return (
        <FilePreviewProvider datasetId={dataset.id}>
            <Form<DatasetFormState>
                mutators={{ ...mutators } as Record<string, Mutator<DatasetFormState>>}
                keepDirtyOnReinitialize={true}
                initialValues={initialValues}
                onSubmit={handleSubmit}
            >
                {({ handleSubmit }) => (
                    <form onSubmit={handleSubmit}>
                        <div className={styles.container}>
                            <div className={styles.info}>
                                <DatasetInfo dataset={dataset} />
                            </div>
                            <div className={styles.main}>
                                <GeneratorSetup dataset={dataset} />
                            </div>
                            <div className={styles.meta}>
                                <DatasetMeta datasetName={dataset.name} />
                            </div>
                        </div>
                    </form>
                )}
            </Form>
            <FilePreviewDialog />
        </FilePreviewProvider>
    );
}
