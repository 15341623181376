import { usePolling } from '../../hooks/usePolling';
import { instrumentation } from '../../instrumentation/instrumentation';
import { DownloadAllFilesButton } from '../../pages/Dataset/DownloadAllFilesButton';
import { QuiModalDialog, QuiModalContent, QuiText, useQuiToasts, QuiBox, useQuiModal } from '@tonicai/ui-quinine';
import { UNSUPPORTED_FILE_EXTENSIONS } from '../../utils';
import { FileAdderFileItem } from './FileAdderFileItem';
import { useField, useForm } from 'react-final-form';
import { useCallback, useState, useMemo, useRef, useEffect } from 'react';
import { UploadFilesButton } from '../UploadFilesButton/UploadFilesButton';
import { useIsMounted } from '../../hooks/useIsMounted';
import { UploadFiles } from '../UploadFiles/UploadFiles';
import { fetchDataset, fetchUsage, uploadFiles, useDataset } from '@/stores';
import { isAxiosError } from 'axios';
import styles from './FileAdder.module.scss';

type FileAdderProps = Readonly<{
    name: string;
    mode: 'dropzone' | 'combobox' | 'button';
    datasetId: string;
}>;

export function FileAdder({ name, mode, datasetId }: FileAdderProps) {
    const dataset = useDataset(datasetId);
    const [uploading, setUploading] = useState(false);
    const form = useForm();
    const isMounted = useIsMounted();
    const addExistingFileInDatasetDialog = useQuiModal();
    const [matchingFileErrorDetails, setMatchingFileErrorDetails] = useState<string | undefined>();
    const addToast = useQuiToasts();
    const uploadAbortControllerRef = useRef<AbortController | null>(null);

    useEffect(() => {
        return () => {
            if (uploadAbortControllerRef.current) {
                uploadAbortControllerRef.current.abort();
            }
        };
    }, []);

    const refetchDataset = useCallback(() => {
        fetchDataset(datasetId);
        fetchUsage();
    }, [datasetId]);

    const {
        input: { value },
    } = useField<string[]>(name, {
        format: (value: string[]) => {
            if (!Array.isArray(value)) {
                return [];
            }

            return Array.from(new Set([...value]));
        },
        parse: (value: unknown) => {
            if (!Array.isArray(value)) {
                return [];
            }
            return Array.from(new Set([...(value as string[])]));
        },
    });

    const hasAtLeastOneFileRequiringPolling = useMemo(() => {
        if (!dataset) {
            return false;
        }

        const assignedDatasetFiles = dataset.files.filter((d) => {
            return Array.isArray(value) && value.includes(d.fileId);
        });
        return assignedDatasetFiles.find((f) => f.processingStatus === 'Queued' || f.processingStatus === 'Running') !== undefined;
    }, [value, dataset]);

    usePolling(refetchDataset, hasAtLeastOneFileRequiringPolling, 5000);

    const removeNonTextFiles = useCallback(
        (files: File[]) => {
            const { textFiles, nonTextFiles } = files.reduce(
                (acc, file) => {
                    const fileExtension = file.name.split('.').pop()?.toLowerCase();

                    if (UNSUPPORTED_FILE_EXTENSIONS.has(fileExtension ?? '')) {
                        acc.nonTextFiles.push(file);
                    } else {
                        acc.textFiles.push(file);
                    }
                    return acc;
                },
                { textFiles: [] as File[], nonTextFiles: [] as File[] }
            );

            if (nonTextFiles.length > 0) {
                instrumentation.uploadNonTextFiles(nonTextFiles.map((f) => f.name));

                const toastTitleMessage =
                    nonTextFiles.length === 1
                        ? `"${nonTextFiles[0].name}" is not a text file`
                        : 'The following files are not text files: ' +
                          nonTextFiles
                              .map(function (f) {
                                  return `"${f.name}"`;
                              })
                              .join(', ');

                addToast({
                    title: `${toastTitleMessage}. Tonic Textual only supports text files, text delimited files, PDF files, MS Word documents (docx), Excel files (xlsx), and common image formats (jpg, png, tif)`,
                    variant: 'destructive',
                    timeout: 10000,
                });
            }

            return textFiles;
        },
        [addToast]
    );

    const onFilesSelected = useCallback(
        async (files: File[]) => {
            setUploading(true);

            const textFiles = removeNonTextFiles(files);
            const datasetToAddFilesTo = datasetId;

            uploadAbortControllerRef.current?.abort();
            uploadAbortControllerRef.current = new AbortController();

            await uploadFiles(textFiles, datasetId ?? datasetToAddFilesTo, uploadAbortControllerRef.current.signal)
                .catch((error: unknown) => {
                    if (isAxiosError(error)) {
                        if (error.response?.status === 409) {
                            const errorDetails = error.response.data;
                            setMatchingFileErrorDetails(errorDetails);
                            addExistingFileInDatasetDialog.open();
                        }
                        if (error.response?.status === 400) {
                            addToast({
                                variant: 'destructive',
                                title: error.response.data,
                                timeout: 10000,
                            });
                        }
                    }
                    setUploading(false);
                    return [];
                })
                .then(() => {
                    form.submit();
                    if (isMounted()) {
                        setUploading(false);
                    }
                });
        },
        [isMounted, datasetId, form, addExistingFileInDatasetDialog, addToast, removeNonTextFiles]
    );

    if (!dataset) return null;

    return (
        <QuiBox display="flex" flexDirection="column" gap="sm">
            <QuiBox display={'flex'} gap={'sm'}>
                {(mode === 'button' || mode === 'combobox') && (
                    <UploadFilesButton
                        variant="brand-purple"
                        className={styles.uploadFilesButton}
                        iconLeft="upload"
                        disabled={uploading}
                        onFilesSelected={onFilesSelected}
                        datasetId={datasetId}
                        fileSource={dataset.fileSource}
                    >
                        Upload Files
                    </UploadFilesButton>
                )}
                {mode === 'combobox' && <DownloadAllFilesButton datasetId={dataset.id} fileSource={dataset.fileSource} />}
            </QuiBox>

            {mode === 'dropzone' && <UploadFiles onFilesSelected={onFilesSelected} />}

            <QuiBox gap="xs" display="flex" flexDirection="column">
                {value.map((datasetFileId) => {
                    const file = dataset.files.find((f) => f.fileId === datasetFileId);

                    if (!file || !dataset) return null;

                    return <FileAdderFileItem key={datasetFileId} fileId={file.fileId} datasetId={dataset.id} />;
                })}
            </QuiBox>
            <QuiModalDialog
                disableDismissOnEscapeKeyDown={false}
                title="Matching File Exists"
                isOpen={addExistingFileInDatasetDialog.isOpen && !!matchingFileErrorDetails}
                onClose={addExistingFileInDatasetDialog.close}
            >
                <QuiModalContent>
                    <QuiBox display="flex" flexDirection="column" gap={'md'}>
                        <QuiText>{matchingFileErrorDetails}</QuiText>
                    </QuiBox>
                </QuiModalContent>
            </QuiModalDialog>
        </QuiBox>
    );
}
