import { atom } from 'jotai';
import { nanoid } from 'nanoid';
import { client } from '../../services/HTTPClient';
import { AxiosResponse, isCancel } from 'axios';
import { FetchStatus, PiiTypeEnum, RedactedFromApiResponseModel, BaseDocument } from '../../types';
import { JotaiStore, globalStore } from '../../stores/globalStore';
import { hasOpenAiKeyAtom } from '../../stores/settings';

type PlaygroundStatus = Extract<FetchStatus, 'init' | 'loading' | 'error' | 'success'>;

type RedactedTextChunk = {
    id: string;
    text: string;
    label?: string;
    score?: number;
};

type PreviewData = {
    id: string;
    chunks: RedactedTextChunk[];
};

type DeIdentifyResult = {
    start: number;
    end: number;
    label: string;
    text: string;
    score: number;
};

type SynthesisRequest = {
    text: string;
};

type SingleDetectionResult = {
    start: number;
    end: number;
    label: PiiTypeEnum;
    text: string;
    score: number;
    jsonPath?: string;
};

type RedactedFromApiResponse = {
    originalText: string;
    redactedText: string;
    usage: number;
    deIdentifyResults: SingleDetectionResult[];
};

// In milliseconds. Bumped this up from 500 so that slow typers don't get rate-limited as often
const REQUEST_DELAY = 800;

/**
 * ATOMS
 */

export const abortControllerAtom = atom<AbortController>(new AbortController());

export const blockTextContentAtom = atom<string[]>([]);

export const blockTextContentEmptyAtom = atom<boolean>((get) => {
    return (
        get(blockTextContentAtom)
            .map((s) => s.trim())
            .join('')
            .trim().length === 0
    );
});

export const responseStaleOrMissingAtom = atom((get) => {
    const deidResponse = get(deidResponseAtom);

    if (!deidResponse) return true;

    return deidResponse.originalText !== get(blockTextContentAtom).join(' ');
});

export const blockTextOffsetAtom = atom((get) => {
    let offset = 0;
    return (get(blockTextContentAtom) ?? []).map((content, index) => {
        const currentBlockOffset = offset + index;
        offset += content.length;
        return currentBlockOffset;
    });
});

export const deidResponseAtom = atom<RedactedFromApiResponseModel | null>(null);
export const contentAtom = atom<BaseDocument | null>(null);

export const timeoutIdAtom = atom<null | number>(null);

export const deidResponseStatusAtom = atom<PlaygroundStatus>('init');

export const llmSynthesisEnabledAtom = atom(true);

// Take into account the value of whether the user has an OpenAI API key set or not
// this is the source of trutch for whether to use the LLM Synthesis endpoint or not
// We should probably just make it a URL parameter on the endpoint that has a ?llm=true
// param or something like that and then only use LLM Synthesis on the backend if that's
// set to true AND the user has an OpenAI API key set in the backend.
export const llmSynthesisActuallyEnabledAtom = atom((get) => {
    return get(llmSynthesisEnabledAtom) && globalStore.get(hasOpenAiKeyAtom);
});

export const llmSynthesisAbortControllerAtom = atom<AbortController | null>(null);

export const llmSynthesisResponseStatusAtom = atom<PlaygroundStatus>('init');

export const llmSynthesisResponseAtom = atom<RedactedFromApiResponse | null>(null);

export const previewDataAtom = atom<PreviewData[] | string | null>((get) => {
    const deidResponse = get(deidResponseAtom);
    const blockTextContent = get(blockTextContentAtom);
    const blockTextOffsets = get(blockTextOffsetAtom);
    const llmSynthesisEnabled = get(llmSynthesisActuallyEnabledAtom);
    const llmSynthesisResponse = get(llmSynthesisResponseAtom);

    // If LLM Synthesis is enabled & we've got a response back from the LLM synthesis
    // endpoint, return the LLM Synthesis result
    if (llmSynthesisEnabled && llmSynthesisResponse) return llmSynthesisResponse.redactedText;

    // If LLM Synthesis is _not_ enabled, and we have a deidResponse, return the
    // redacted text chunks after formatting them for the preview
    if (!llmSynthesisEnabled && deidResponse) {
        return blockTextOffsets.map((_, index) => {
            const content = blockTextContent[index];

            return {
                id: nanoid(),
                chunks: getRedactionTextChunksForPlayground(content, deidResponse, blockTextOffsets?.[index] ?? 0),
            };
        });
    }

    // In all other cases, return null
    return null;
});

export const previewStatusAtom = atom<'idle' | 'loading' | 'error' | 'success'>((get) => {
    const deidResponseStatus = get(deidResponseStatusAtom);
    const llmSynthesisEnabled = get(llmSynthesisActuallyEnabledAtom);
    const llmSynthesisResponseFetchStatus = get(llmSynthesisResponseStatusAtom);
    const blockTextContentEmpty = get(blockTextContentEmptyAtom);
    const previewData = get(previewDataAtom);

    if (blockTextContentEmpty) return 'idle';

    if (llmSynthesisEnabled && llmSynthesisResponseFetchStatus === 'success' && previewData) return 'success';
    if (llmSynthesisEnabled && llmSynthesisResponseFetchStatus === 'error') return 'error';
    if (llmSynthesisEnabled && llmSynthesisResponseFetchStatus === 'loading') return 'loading';

    if (!llmSynthesisEnabled && deidResponseStatus === 'error') return 'error';
    if (!llmSynthesisEnabled && deidResponseStatus === 'loading') return 'loading';
    if (!llmSynthesisEnabled && deidResponseStatus === 'success' && previewData) return 'success';

    return 'idle';
});

/**
 * Internal functions
 */

export function getRedactionTextChunksForPlayground(text: string, response: RedactedFromApiResponseModel, offset: number): RedactedTextChunk[] {
    const textChunks: RedactedTextChunk[] = [];

    let currentChunkString = '';
    let inRedaction: boolean | null = false;
    let previousRedaction: DeIdentifyResult | undefined = undefined;

    for (let i = 0; i < text.length; i++) {
        const actualI = i + offset;
        const detectedEntities = response.deIdentifyResults;
        const currentRedaction = detectedEntities.find((d) => {
            return actualI >= d.start && actualI < d.end;
        });

        const currentIndexInRedaction = currentRedaction !== undefined;

        if (currentIndexInRedaction !== inRedaction) {
            if (currentChunkString.length > 0) {
                textChunks.push({
                    id: nanoid(),
                    text: currentChunkString,
                    label: previousRedaction?.label,
                    score: previousRedaction?.score,
                });
                currentChunkString = '';
            }
            previousRedaction = currentRedaction;
            inRedaction = currentIndexInRedaction;
        }

        currentChunkString += text.charAt(i);

        if (i === text.length - 1) {
            textChunks.push({
                id: nanoid(),
                text: currentChunkString,
                label: previousRedaction?.label,
                score: previousRedaction?.score,
            });
        }
    }

    return textChunks;
}

function fetchLLMSynthesisResponse(store: JotaiStore) {
    if (store.get(llmSynthesisActuallyEnabledAtom) === false) return;

    const blockTextContent = store.get(blockTextContentAtom);
    const blockTextContentEmpty = store.get(blockTextContentEmptyAtom);

    if (blockTextContentEmpty) return;

    store.get(llmSynthesisAbortControllerAtom)?.abort();
    const abortController = new AbortController();

    store.set(llmSynthesisAbortControllerAtom, abortController);
    store.set(llmSynthesisResponseStatusAtom, 'loading');

    client
        .post<RedactedFromApiResponse, AxiosResponse<RedactedFromApiResponse>, SynthesisRequest>(
            '/api/synthesis',
            { text: blockTextContent.join(' ') },
            {
                signal: abortController.signal,
            }
        )
        .then(({ data }) => {
            store.set(llmSynthesisResponseAtom, data);
            store.set(llmSynthesisResponseStatusAtom, 'success');
        })
        .catch((error) => {
            if (!isCancel(error)) {
                store.set(llmSynthesisResponseStatusAtom, 'error');
            }
        });
}

/**
 * API
 */

export function toggleLLMSynthesis(store: JotaiStore) {
    store.set(llmSynthesisEnabledAtom, (current) => !current);
    fetchDeidentifyResponse(store);
}

export function clearDeidResponse(store: JotaiStore) {
    store.set(deidResponseAtom, null);
    store.set(llmSynthesisResponseAtom, null);
    store.get(abortControllerAtom).abort();
    store.set(abortControllerAtom, new AbortController());
}

export function fetchDeidentifyResponse(store: JotaiStore) {
    const llmSynthesisActuallyEnabled = store.get(llmSynthesisActuallyEnabledAtom);

    if (llmSynthesisActuallyEnabled) {
        fetchLLMSynthesisResponse(store);
        return;
    }

    const text = store.get(blockTextContentAtom).join(' ');

    if (text.trim().length === 0) return;

    // Cancel any existing requests (Which shoudn't exist, but just in case...)
    const currentAbortController = store.get(abortControllerAtom);
    currentAbortController.abort();
    const abortController = new AbortController();
    store.set(abortControllerAtom, abortController);

    // Set the fetch status to loading
    store.set(deidResponseStatusAtom, 'loading');

    client
        .post<RedactedFromApiResponseModel>(
            '/api/redact',
            {
                text: store.get(blockTextContentAtom).join(' '),
            },
            {
                signal: store.get(abortControllerAtom).signal,
            }
        )
        .then(({ data }) => {
            store.set(deidResponseAtom, data);
            store.set(deidResponseStatusAtom, 'success');
        })
        .catch((e) => {
            if (!isCancel(e)) {
                store.set(deidResponseStatusAtom, 'error');
            }
        });
}

export function updateBlockTextContent(store: JotaiStore, blockTextContent: string[], isPasteEvent?: boolean) {
    const currentValue = store.get(blockTextContentAtom).join(' ');
    const newValue = blockTextContent.join(' ');

    if (currentValue === newValue) return;

    store.set(blockTextContentAtom, blockTextContent);

    store.set(abortControllerAtom, (abortController) => {
        abortController.abort();
        return new AbortController();
    });

    if (isPasteEvent) {
        store.set(timeoutIdAtom, (currentTimeoutId) => {
            if (currentTimeoutId !== null) {
                window.clearTimeout(currentTimeoutId);
            }

            fetchDeidentifyResponse(store);

            return null;
        });
    } else {
        store.set(timeoutIdAtom, (currentTimeoutId) => {
            if (currentTimeoutId !== null) {
                window.clearTimeout(currentTimeoutId);
            }

            return window.setTimeout(() => {
                fetchDeidentifyResponse(store);
            }, REQUEST_DELAY);
        });
    }
}
