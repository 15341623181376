import { QuiBox, QuiFlexH, QuiSpinner } from '@tonicai/ui-quinine';
import { useMemo, useState } from 'react';
import { useDebounce } from '../../hooks/useDebounce';
import { useEndpoint } from '../../hooks/useEndpoint';
import { Message } from '../Message/Message';
import { useCloudFileExplorer } from './CloudFileExplorer';
import { CloudFileExplorerRow } from './CloudFileExplorerRow';
import { ScrollView } from './ScrollView';
import { SearchBar } from './SearchBar';
import { CloudStorageItem } from './types';

type BucketsResponse = CloudStorageItem[];

type KeysResponse = {
    keys: CloudStorageItem[];
    isTruncated: boolean;
    maxKeys: number;
};

export function LevelItems() {
    const { fileSystemType, parseJobConfigId, currentLevel, levels, pathSegments, allowFileSelection } = useCloudFileExplorer();

    const [searchPrefix, setSearchPrefix] = useState<string>('');
    const debouncedSearchPrefix = useDebounce(searchPrefix, 500);

    const getAwsEndpoint = (baseUrl: string, currentLevel: number, pathSegments: string[], debouncedSearchPrefix: string) => {
        let endpoint = '';
        const params = new URLSearchParams();

        if (currentLevel === 0) {
            endpoint = `${baseUrl}/buckets`;
            if (debouncedSearchPrefix) {
                params.set('bucketPrefix', debouncedSearchPrefix);
            }
        } else {
            const bucketName = pathSegments[0];
            const prefix = pathSegments.slice(1).join('/');
            endpoint = `${baseUrl}/keys?bucketName=${bucketName}&prefix=${prefix}`;
            if (debouncedSearchPrefix) {
                params.set('searchPrefix', debouncedSearchPrefix);
            }
        }

        const queryString = params.toString();
        return queryString ? `${endpoint}?${queryString}` : endpoint;
    };

    const getDatabricksEndpoint = (
        baseUrl: string,
        currentLevel: number,
        levels: string[],
        pathSegments: string[],
        debouncedSearchPrefix: string
    ) => {
        const params = new URLSearchParams();
        if (debouncedSearchPrefix) params.set('searchPrefix', debouncedSearchPrefix);

        switch (levels[currentLevel]) {
            case 'catalog':
                return `${baseUrl}/catalogs?${params}`;
            case 'schema':
                params.set('catalogName', pathSegments[0]);
                return `${baseUrl}/schemas?${params}`;
            case 'volume':
                params.set('catalogName', pathSegments[0]);
                params.set('schemaName', pathSegments[1]);
                return `${baseUrl}/volumes?${params}`;
            default: {
                // 'folder' or 'file'
                params.set('catalogName', pathSegments[0]);
                params.set('schemaName', pathSegments[1]);
                params.set('volumeName', pathSegments[2]);
                const folders = pathSegments.slice(3).join('+');
                if (folders) {
                    params.set('folders', folders);
                } else {
                    params.set('folders', '');
                }
                return `${baseUrl}/paths?${params}`;
            }
        }
    };

    const apiEndpoint = useMemo(() => {
        const baseUrl = fileSystemType === 'aws' ? `/api/cloudfile/${parseJobConfigId}` : `/api/databricks/${parseJobConfigId}`;

        if (fileSystemType === 'aws') {
            return getAwsEndpoint(baseUrl, currentLevel, pathSegments, debouncedSearchPrefix);
        }

        if (fileSystemType === 'databricks') {
            return getDatabricksEndpoint(baseUrl, currentLevel, levels, pathSegments, debouncedSearchPrefix);
        }

        return baseUrl;
    }, [fileSystemType, parseJobConfigId, currentLevel, levels, pathSegments, debouncedSearchPrefix]);

    const { data, fetchStatus } = useEndpoint<BucketsResponse | KeysResponse>(apiEndpoint);

    const sortedItems = useMemo(() => {
        if (!data || (!Array.isArray(data) && !Array.isArray(data.keys))) {
            return [];
        }

        const items = Array.isArray(data) ? data : data.keys;

        const filteredItems = items.filter((item) => item && item.name !== '');

        return filteredItems.sort((a, b) => {
            // Sort folders first, then files
            if (a.type !== b.type) {
                return a.type === 'File' ? 1 : -1;
            }
            // Then sort alphabetically
            return a.name.localeCompare(b.name);
        });
    }, [data]);

    const isTruncated = useMemo(() => {
        if (Array.isArray(data)) {
            return data.length === 1000;
        } else {
            return data?.isTruncated ?? false;
        }
    }, [data]);

    if (fetchStatus === 'loading' || fetchStatus === 'init') {
        return (
            <QuiFlexH fullWidth justify="center" padding="lg">
                <QuiSpinner />
            </QuiFlexH>
        );
    }

    if (fetchStatus === 'error') {
        return (
            <QuiFlexH fullWidth padding="md" justify="center">
                <Message variant="error">Error Fetching Items</Message>
            </QuiFlexH>
        );
    }

    return (
        <>
            <SearchBar isTruncated={isTruncated} value={searchPrefix} setValue={setSearchPrefix} status={fetchStatus} />
            <ScrollView>
                {!data && fetchStatus === 'success' && (
                    <QuiBox padding="lg" textAlign="center" text="text-sm" color="text-secondary">
                        No data received from API
                    </QuiBox>
                )}
                {sortedItems.length === 0 ? (
                    <QuiBox padding="lg" textAlign="center" text="text-sm" color="text-secondary">
                        No Items Found
                    </QuiBox>
                ) : (
                    sortedItems.map((item) => (
                        <CloudFileExplorerRow
                            key={item.name}
                            type={item.type}
                            name={item.name}
                            isSelectable={item.type !== 'File' || allowFileSelection}
                        />
                    ))
                )}
            </ScrollView>
        </>
    );
}
