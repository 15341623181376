import { QuiFlexBoxColumn } from '@tonicai/ui-quinine';
import { ParseJobFileParseJobs } from '../../components/ParseJobFileParseJobs/ParseJobFileParseJobs';
import { useEffect } from 'react';
import { fetchParseJobConfigFileParseJobs, fetchParseJobConfigFiles } from '../../stores/parse-jobs';
import { PageTitle } from '../../components/PageTitle/PageTitle';
import { FilesTable } from '../FileParseJob/FilesTable';
import { EndpointGuard } from '../../components/EndpointGuard/EndpointGuard';
import { FilesParsedResponse } from '../../types';
import { Tabs } from '../../components/Tabs/Tabs';
import { PipelineEntityConfigForm } from './PipelineEntityConfigForm';
import { usePipelineContext } from '../../components/PipelineLayout/usePipelineContext';

export function AmazonS3Pipeline() {
    const { parseJobConfigId, parseJobConfig } = usePipelineContext();

    useEffect(() => {
        fetchParseJobConfigFileParseJobs(parseJobConfigId);
        fetchParseJobConfigFiles(parseJobConfigId);
    }, [parseJobConfigId]);

    return (
        <QuiFlexBoxColumn gap="md">
            <PageTitle icon="list" title="Overview" />

            <Tabs.Container defaultTab="files">
                <Tabs.TabTriggers>
                    <Tabs.TabTrigger data-test="pipeline-files-tab" icon="inbox" id="files">
                        Files
                    </Tabs.TabTrigger>
                    <Tabs.TabTrigger data-test="pipeline-runs-tab" icon="shield" id="runs">
                        Pipeline Runs
                    </Tabs.TabTrigger>
                    {parseJobConfig.synthesizeFiles ? (
                        <Tabs.TabTrigger data-test="generator-config" icon="eye" id="generator-config">
                            Generator Config
                        </Tabs.TabTrigger>
                    ) : null}
                </Tabs.TabTriggers>
                <Tabs.TabContent id="files">
                    <EndpointGuard<FilesParsedResponse>
                        endpoint={`/api/parsejobconfig/${parseJobConfigId}/files/all`}
                        errorMessage="Error loading files"
                        Component={FilesTable}
                        notFoundMessage="No files found"
                    />
                </Tabs.TabContent>
                <Tabs.TabContent id="runs">
                    <ParseJobFileParseJobs />
                </Tabs.TabContent>
                {parseJobConfig.synthesizeFiles ? (
                    <Tabs.TabContent id="generator-config">
                        <PipelineEntityConfigForm />
                    </Tabs.TabContent>
                ) : null}
            </Tabs.Container>
        </QuiFlexBoxColumn>
    );
}
