import { QuiBox, QuiButton, QuiIcon } from '@tonicai/ui-quinine';
import { Fragment, useEffect, useRef } from 'react';
import styles from './Breadcrumbs.module.scss';
import { useCloudFileExplorer } from './CloudFileExplorer';

export function Breadcrumbs() {
    const { fileSystemType, currentLevel, pathSegments, goHome, goToLevel } = useCloudFileExplorer();

    const containerRef = useRef<HTMLDivElement>(null);

    const childrenRefs = useRef<HTMLElement[]>([]);

    useEffect(() => {
        if (!containerRef.current) return;
        if (childrenRefs.current.length < 1) return;

        const childrenWidths = childrenRefs.current
            .map((ref) => {
                return ref.getBoundingClientRect().width;
            })
            .reduce((acc, curr) => acc + curr, 0);

        if (containerRef.current.clientWidth < childrenWidths) {
            alert('too many children to contain!');
        }
    }, []);

    childrenRefs.current = [];

    return (
        <QuiBox display="flex" ref={containerRef} gap="xs" alignItems="center" padding="xs">
            <QuiButton variant="minimal" type="button" iconLeft="home" onClick={goHome} size="sm">
                {fileSystemType === 'aws' ? 'S3' : 'Databricks'}
            </QuiButton>

            {pathSegments.map((segment, index) => {
                const uniqueKey = `${segment}-${index}`;
                return (
                    <Fragment key={uniqueKey}>
                        <div>/</div>
                        <button
                            type="button"
                            className={styles.pathButton}
                            disabled={index === currentLevel - 1}
                            onClick={() => goToLevel(index + 1)}
                        >
                            <QuiIcon icon="folder" />
                            <span>{segment}</span>
                        </button>
                    </Fragment>
                );
            })}
        </QuiBox>
    );
}
