import { QuiBox, QuiSpinner } from '@tonicai/ui-quinine';
import { SupportedLanguage } from './types';
import { useEffect, useMemo, useRef, useState } from 'react';
import { HightlightCodeRequestMessage, hightlightCodeResponseMessageSchema } from './schema';
import { nanoid } from 'nanoid';
import 'highlight.js/styles/stackoverflow-light.css';
import styles from './CodeBlock.module.scss';
import classNames from 'classnames';

export type CodeBlockProps = Readonly<{
    code: string;
    language: SupportedLanguage;
    wrapLongLines?: boolean;
}>;

export function CodeBlock({ code, language, wrapLongLines }: CodeBlockProps) {
    const isMountedRef = useRef(false);
    const id = useMemo(() => nanoid(), []);
    const workerRef = useRef<Worker | null>(null);

    const [html, setHTML] = useState<string | null>(null);

    useEffect(() => {
        isMountedRef.current = true;
        return () => {
            isMountedRef.current = false;
        };
    }, []);

    useEffect(() => {
        workerRef.current = new Worker(new URL('./worker.ts', import.meta.url), {
            type: 'module',
        });

        workerRef.current.onmessage = async function messageHandler(e) {
            try {
                const data = JSON.parse(e.data);
                const result = await hightlightCodeResponseMessageSchema.safeParseAsync(data);

                if (!result.success) return;

                if (data.id !== id) return;

                if (!isMountedRef.current) return;

                setHTML(result.data.html);
            } catch (error) {
                console.error('error', error);
            }
        };

        return () => {
            workerRef.current?.terminate();
        };
    }, [id]);

    useEffect(() => {
        if (!workerRef.current) return;
        try {
            const message: HightlightCodeRequestMessage = {
                code,
                language,
                id,
            };
            workerRef.current.postMessage(JSON.stringify(message));
        } catch (e) {
            console.error(e);
        }
    }, [code, language, id]);

    if (!html) {
        return (
            <QuiBox padding="sm">
                <QuiSpinner size="sm" />
            </QuiBox>
        );
    }

    const className = classNames('hljs', 'theme-stackoverflow-light', `language-${language}`, styles.code, {
        [styles.wrapLongLines]: wrapLongLines,
    });

    return (
        <pre>
            <code className={className} dangerouslySetInnerHTML={{ __html: html }} />
        </pre>
    );
}
