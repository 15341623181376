import { QuiBox, QuiButton, QuiFlexBoxColumn, QuiModalContent, QuiModalDialog } from '@tonicai/ui-quinine';
import { SubscriptionBadge } from './SubscriptionBadge';
import { CloudSubscriptionStatus } from '@/types';
import styles from './PlanDialog.module.scss';
import { useBillingStatus } from '@/stores';
import { SelectPayAsYouGoPlan } from './SelectPayAsYouGoPlan';

type PlanDialogProps = Readonly<{
    isOpen: boolean;
    onClose: () => void;
}>;

export function PlanDialog({ isOpen, onClose }: PlanDialogProps) {
    const status = useBillingStatus();

    return (
        <QuiModalDialog transitionDuration={0} isOpen={isOpen} onClose={onClose}>
            <QuiModalContent>
                <QuiFlexBoxColumn className={styles.content} gap="lg">
                    <QuiBox padding="md none none none">
                        <QuiBox text="text-lg" weight="medium">
                            Select your plan
                        </QuiBox>
                    </QuiBox>
                    <div className={styles.columns}>
                        <div>
                            <QuiBox border={status === CloudSubscriptionStatus.None ? 'green-500' : 'white-600'} borderRadius="md" padding="lg">
                                <QuiFlexBoxColumn gap="md">
                                    <QuiBox>
                                        <SubscriptionBadge status={CloudSubscriptionStatus.None} />
                                    </QuiBox>
                                    <QuiBox weight="medium">Limited to 100K words</QuiBox>
                                    <QuiBox color="text-secondary">
                                        Scan up to 100,000 words with the Free Trial plan. Upgrade your plan once your limit is reached.
                                    </QuiBox>
                                    <QuiBox>
                                        <QuiButton disabled variant="minimal">
                                            Current Plan
                                        </QuiButton>
                                    </QuiBox>
                                </QuiFlexBoxColumn>
                            </QuiBox>
                        </div>
                        <div>
                            <QuiBox border={status === CloudSubscriptionStatus.Active ? 'green-500' : 'white-600'} borderRadius="md" padding="lg">
                                <QuiFlexBoxColumn gap="md">
                                    <QuiBox>
                                        <SubscriptionBadge status={CloudSubscriptionStatus.Active} />
                                    </QuiBox>
                                    <QuiBox weight="medium">Unlock unlimited words</QuiBox>
                                    <QuiBox color="text-secondary">
                                        Upgrade your plan to continue using Textual without limits. Plans start at $0.89 per 1,000 words.
                                    </QuiBox>
                                    <QuiBox>
                                        <SelectPayAsYouGoPlan />
                                    </QuiBox>
                                </QuiFlexBoxColumn>
                            </QuiBox>
                        </div>
                    </div>
                    <QuiBox margin="md none">
                        <QuiBox display="flex" justifyContent="space-between" alignItems="center">
                            <QuiButton type="button" onClick={onClose}>
                                Cancel
                            </QuiButton>
                        </QuiBox>
                    </QuiBox>
                </QuiFlexBoxColumn>
            </QuiModalContent>
        </QuiModalDialog>
    );
}
