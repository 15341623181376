import { QuiBox, QuiDivider, QuiFlexBoxColumn, QuiLink } from '@tonicai/ui-quinine';
import styles from './PipelineLayout.module.scss';
import { ReactNode } from 'react';
import { CreateApiKeyButton } from '../CreateApiKeyButton/CreateApiKeyButton';
import { SDK_DOCUMENTATION_URL } from '../../constants';
import { CopyToClipboardButton } from '../CopyToClipboardButton/CopyToClipboardButton';
import { getPipelineCodeSnippet } from '../../utils/getPipelineCodeSnippet';
import { usePipelineContext } from './usePipelineContext';

function StepNumber({ children }: Readonly<{ children: string }>) {
    return <div className={styles.stepNumber}>{children}</div>;
}

type NextStepsStepProps = Readonly<{
    stepNumber: string;
    title: ReactNode;
    description: ReactNode;
    children?: ReactNode;
}>;

function NextStepStep({ stepNumber, title, description, children }: NextStepsStepProps) {
    return (
        <QuiBox display="flex" gap="md">
            <div>
                <StepNumber>{stepNumber}</StepNumber>
            </div>
            <QuiFlexBoxColumn gap="md">
                <div>
                    <QuiBox text="text-xs" weight="medium">
                        {title}
                    </QuiBox>
                    <QuiBox text="text-xs" color="text-secondary">
                        {description}
                    </QuiBox>
                </div>
                {children ? <QuiBox>{children}</QuiBox> : null}
            </QuiFlexBoxColumn>
        </QuiBox>
    );
}

export function NextSteps() {
    const { parseJobConfigId } = usePipelineContext();
    return (
        <QuiFlexBoxColumn padding="lg" gap="lg" style={{ width: '350px' }}>
            <QuiBox text="text-md" weight="medium">
                Next steps
            </QuiBox>

            <NextStepStep
                stepNumber="1"
                title="Create an API Key"
                description="With the Textual API, you can use a simple Python script to fetch your redacted data."
            >
                <CreateApiKeyButton />
            </NextStepStep>

            <NextStepStep
                stepNumber="2"
                title="Install the Textual API"
                description={
                    <>
                        Install the <code>tonic-textual</code> Python package.
                    </>
                }
            >
                <CopyToClipboardButton size="sm" icon="copy" content="pip install tonic-textual">
                    Copy Code
                </CopyToClipboardButton>
            </NextStepStep>

            <QuiDivider />

            <QuiBox display="flex" justifyContent="space-between" alignItems="center">
                <QuiBox weight="medium" text="text-md">
                    Steps 3-6
                </QuiBox>
                <CopyToClipboardButton icon="copy" content={getPipelineCodeSnippet(parseJobConfigId)} size="sm">
                    Copy Code
                </CopyToClipboardButton>
            </QuiBox>

            <NextStepStep
                stepNumber="3"
                title="Redact or synthesize entities"
                description="Specify rules for redacting or synthesizing entities to protect the privacy of your data."
            />

            <NextStepStep
                stepNumber="4"
                title="Identify important entity metadata"
                description="Specify the entity metadata to include. The presence of entity metadata improves the accuracy and relevance of your LLM’s responses."
            />

            <NextStepStep
                stepNumber="5"
                title="Create chunks"
                description="Separate the documents into meaningful chunks using either our chunking strategy or your own."
            />

            <NextStepStep
                stepNumber="6"
                title="Deploy"
                description="Integrate Textual's enriched and governed data pipelines with your favorite embedding model, vector database, or fine-tuning platform."
            />

            <QuiLink external={true} variant="currentcolor" size="text-sm" to={SDK_DOCUMENTATION_URL} iconRight="external-link">
                View SDK Documentation
            </QuiLink>
        </QuiFlexBoxColumn>
    );
}
