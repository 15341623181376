import { QuiBox, QuiButton } from '@tonicai/ui-quinine';
import { isCancel } from 'axios';
import isEqual from 'fast-deep-equal';
import { FieldSubscription } from 'final-form';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useField, useFormState } from 'react-final-form';
import { client } from '../../services/HTTPClient';
import { AwsCredential, DatabricksCredential, FileSourceEnum } from '../../types';
import { FormError } from '../FormError/FormError';
import { Message } from '../Message/Message';

const fieldSubscription: FieldSubscription = {
    value: true,
    valid: true,
    dirtySinceLastSubmit: true,
    touched: true,
    active: true,
    pristine: true,
    modifiedSinceLastSubmit: true,
    modified: true,
};

export interface ITestResponse {
    success: boolean;
    errorMessage: string | undefined;
}

type AWSCredentialsProps = {
    fileSystemType: 'aws';
    awsKeyFieldName: string;
    awsAccessSecretFieldName: string;
    awsRegionFieldName: string;
    awsSessionTokenFieldName: string;
};

type DatabricksCredentialsProps = {
    fileSystemType: 'databricks';
    databricksUrlFieldName: string;
    databricksAccessTokenFieldName: string;
};

type CommonProps = {
    onClick?: () => void;
    showFormError?: boolean;
};

type TestCredentialsButtonProps = CommonProps & (AWSCredentialsProps | DatabricksCredentialsProps);

export function TestCredentialsButton(props: TestCredentialsButtonProps) {
    const { onClick, showFormError, fileSystemType } = props;

    // Destructure AWS props if applicable
    const {
        awsKeyFieldName = '',
        awsAccessSecretFieldName = '',
        awsRegionFieldName = '',
        awsSessionTokenFieldName = '',
    } = fileSystemType === 'aws' ? props : {};

    // Destructure Databricks props if applicable
    const { databricksUrlFieldName = '', databricksAccessTokenFieldName = '' } = fileSystemType === 'databricks' ? props : {};

    // Use empty strings as default values to avoid conditional hook calls
    const awsKeyField = useField(fileSystemType === 'aws' ? awsKeyFieldName : '', { subscription: fieldSubscription });
    const awsAccessSecretField = useField(fileSystemType === 'aws' ? awsAccessSecretFieldName : '', { subscription: fieldSubscription });
    const awsRegionField = useField(fileSystemType === 'aws' ? awsRegionFieldName : '', { subscription: fieldSubscription });
    const awsSessionToken = useField(fileSystemType === 'aws' ? awsSessionTokenFieldName : '', { subscription: fieldSubscription });

    const databricksUrlField = useField(fileSystemType === 'databricks' ? databricksUrlFieldName : '', { subscription: fieldSubscription });
    const databricksAccessTokenField = useField(fileSystemType === 'databricks' ? databricksAccessTokenFieldName : '', {
        subscription: fieldSubscription,
    });

    const lastCheckedValues = useRef<AwsCredential | DatabricksCredential | null>(null);

    const abortControllerRef = useRef<AbortController | null>(null);

    const [testStatus, setTestStatus] = useState<'untested' | 'testing' | 'success' | 'error'>('untested');
    const [errorMessage, setErrorMessage] = useState<string | null>(null);

    const testConnectionEnabled = useMemo(() => {
        if (fileSystemType === 'aws') {
            return awsKeyField.meta.valid && awsAccessSecretField.meta.valid && awsRegionField.meta.valid;
        } else {
            return databricksUrlField.meta.valid && databricksAccessTokenField.meta.valid;
        }
    }, [
        fileSystemType,
        awsKeyField.meta.valid,
        awsAccessSecretField.meta.valid,
        awsRegionField.meta.valid,
        databricksUrlField.meta.valid,
        databricksAccessTokenField.meta.valid,
    ]);

    const form = useFormState({
        subscription: {
            submitting: true,
            submitError: true,
            submitFailed: true,
        },
    });

    useEffect(() => {
        if (form.submitting) {
            setTestStatus('untested');
            setErrorMessage(null);
        }
    }, [form.submitting]);

    useEffect(() => {
        setTestStatus('untested');
        setErrorMessage(null);
    }, [
        fileSystemType,
        awsKeyField.input.value,
        awsAccessSecretField.input.value,
        awsRegionField.input.value,
        awsSessionToken.input.value,
        databricksUrlField.input.value,
        databricksAccessTokenField.input.value,
    ]);

    const testConnection = useCallback(
        (automatic?: boolean) => {
            const data =
                fileSystemType === 'aws'
                    ? ({
                          accessKey: awsKeyField.input.value,
                          secretKey: awsAccessSecretField.input.value,
                          region: awsRegionField.input.value,
                          sessionToken: awsSessionToken.input.value,
                      } as AwsCredential)
                    : ({
                          url: databricksUrlField.input.value,
                          accessToken: databricksAccessTokenField.input.value,
                      } as DatabricksCredential);

            if (automatic && lastCheckedValues.current !== null && isEqual(data, lastCheckedValues.current)) {
                return;
            }

            abortControllerRef.current?.abort();
            abortControllerRef.current = new AbortController();

            lastCheckedValues.current = data;

            setTestStatus('testing');
            setErrorMessage(null);
            client
                .post<ITestResponse>(
                    `/api/cloudfile/test-cloud-connection`,
                    {
                        fileSource: fileSystemType === 'aws' ? FileSourceEnum.Aws : FileSourceEnum.Databricks,
                        credential: data,
                    },
                    { signal: abortControllerRef.current.signal }
                )
                .then((response) => {
                    if (response.data.success) {
                        setTestStatus('success');
                        setErrorMessage(null);
                    } else {
                        setTestStatus('error');
                        setErrorMessage(response.data.errorMessage ?? 'Error');
                    }
                })
                .catch((error) => {
                    if (!isCancel(error)) {
                        console.error(error);
                        setTestStatus('error');
                        setErrorMessage(`Could not connect to ${fileSystemType === 'aws' ? 'AWS' : 'Databricks'}`);
                    }
                });
        },
        [
            fileSystemType,
            awsKeyField.input.value,
            awsAccessSecretField.input.value,
            awsRegionField.input.value,
            awsSessionToken.input.value,
            databricksUrlField.input.value,
            databricksAccessTokenField.input.value,
        ]
    );

    useEffect(() => {
        return () => {
            abortControllerRef.current?.abort();
        };
    }, []);

    return (
        <>
            <QuiButton
                spinner={testStatus === 'testing'}
                iconRight="globe"
                type="button"
                disabled={!testConnectionEnabled || testStatus === 'testing'}
                onClick={() => {
                    testConnection(false);
                    if (onClick) {
                        onClick();
                    }
                }}
            >
                Test {fileSystemType === 'aws' ? 'AWS' : 'Databricks'} Connection
            </QuiButton>

            {/* Success Message */}
            {testStatus === 'success' ? (
                <Message variant="success">Successfully connected to {fileSystemType === 'aws' ? 'AWS' : 'Databricks'}</Message>
            ) : null}

            {/* Error Message */}
            {testStatus === 'error' && !showFormError ? (
                <QuiBox>
                    <Message variant="error">{errorMessage}</Message>
                </QuiBox>
            ) : null}

            {showFormError ? <FormError /> : null}
        </>
    );
}
