import { Outlet } from 'react-router-dom';
import { Navigation } from '../Navigation/Navigation';
import { fetchDatasets, fetchUsage, currentUserAtom, fetchBillingInfo, freeTrialExpiredAtom, usePlanDialogOpen, closePlanDialog } from '@/stores';
import { useEffect, useState } from 'react';
import { useIsMounted } from '../../hooks/useIsMounted';
import { QuiBox, QuiSpinner } from '@tonicai/ui-quinine';
import { Message } from '../Message/Message';
import { fetchParseJobs } from '../../stores/parse-jobs';
import { PageContainer } from '../PageContainer/PageContainer';
import styles from './AppLayout.module.scss';
import { useAtomValue } from 'jotai';
import classNames from 'classnames';
import { FreeTrialBanner } from './FreeTrialBanner';
import { PlanDialog } from '@/pages/Billing/PlanDialog';
import { BillingGuard } from '@/pages/Billing/BillingGuard';

export function AppLayout() {
    const [loadedInitialData, setLoadedInitialData] = useState(false);
    const [error, setError] = useState(false);
    const isMounted = useIsMounted();
    const freeTrialExpired = useAtomValue(freeTrialExpiredAtom);
    const planDialogOpen = usePlanDialogOpen();

    const currentUser = useAtomValue(currentUserAtom);

    useEffect(() => {
        if (!currentUser) return;

        Promise.all([fetchUsage(), fetchDatasets(), fetchParseJobs(), fetchBillingInfo()])
            .then(() => {
                if (isMounted()) {
                    setLoadedInitialData(true);
                    setError(false);
                }
            })
            .catch(() => {
                setError(true);
            });
    }, [isMounted, currentUser]);

    const layoutClassName = classNames(styles.appLayout, {
        [styles.appLayoutWithBannerVisible]: freeTrialExpired !== false,
    });

    const navigationClassName = classNames(styles.navigation, {
        [styles.navigationWithBannerVisible]: freeTrialExpired !== false,
    });

    return (
        <>
            <div className={layoutClassName}>
                <BillingGuard>
                    {freeTrialExpired !== false ? <FreeTrialBanner mode={freeTrialExpired} className={styles.banner} /> : null}
                </BillingGuard>
                <Navigation className={navigationClassName} />
                <main>
                    {loadedInitialData && error && (
                        <QuiBox justifyContent="center" padding="lg" display="flex">
                            <Message variant="error">
                                <QuiBox text="text-sm">Something unexpected happened. Please try refreshing the page.</QuiBox>
                            </Message>
                        </QuiBox>
                    )}
                    {!error &&
                        (loadedInitialData ? (
                            <PageContainer>
                                <Outlet />
                            </PageContainer>
                        ) : (
                            <QuiBox justifyContent="center" padding="lg" display="flex">
                                <QuiSpinner />
                            </QuiBox>
                        ))}
                </main>
            </div>
            <PlanDialog isOpen={planDialogOpen} onClose={closePlanDialog} />
        </>
    );
}
