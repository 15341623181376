import { CloudSubscriptionStatus } from '@/types';
import { QuiBox } from '@tonicai/ui-quinine';

type SubscriptionBadgeProps = Readonly<{
    status: CloudSubscriptionStatus;
}>;

export function SubscriptionBadge({ status }: SubscriptionBadgeProps) {
    if (status === CloudSubscriptionStatus.Active) {
        return (
            <QuiBox display="inline-block" borderRadius="md" text="text-xs" padding="xs sm" bg="green" color="white">
                Pay As You Go
            </QuiBox>
        );
    }

    if (status === CloudSubscriptionStatus.None) {
        return (
            <QuiBox display="inline-block" borderRadius="md" text="text-xs" padding="xs sm" bg="green-50" color="green-700">
                Free Trial
            </QuiBox>
        );
    }

    if (status === CloudSubscriptionStatus.Expired) {
        return (
            <QuiBox display="inline-block" borderRadius="md" text="text-xs" padding="xs sm" bg="brand-orange" color="brand-orange-700">
                Expired
            </QuiBox>
        );
    }

    return null;
}
