import { useBillingStatus, useStripeMetadata } from '@/stores';
import { QuiBox } from '@tonicai/ui-quinine';
import { ReactNode } from 'react';
import { formatBillingDate, formatCurrency } from './utils';
import styles from './BillingMetadata.module.scss';
import { CloudSubscriptionStatus } from '@/types';

type MetadataRowProps = Readonly<{
    label: ReactNode;
    content?: ReactNode;
}>;

function MetadataRow({ label, content }: MetadataRowProps) {
    return (
        <tr>
            <th>{label}</th>
            <td>{content ?? '--'}</td>
        </tr>
    );
}

type BillingMetadataProps = Readonly<{
    lastPaymentDate?: boolean;
    nextPaymentDate?: boolean;
    currentBalance?: boolean;
    customerName?: boolean;
    lastFour?: boolean;
    // wordsUsed?: boolean;
    subscription?: boolean;
}>;

const SUBSCRIPTION_LABELS: Record<CloudSubscriptionStatus, string> = {
    [CloudSubscriptionStatus.None]: 'None',
    [CloudSubscriptionStatus.Expired]: 'Expired',
    [CloudSubscriptionStatus.Active]: 'Pay As You Go',
};

export function BillingMetadata({
    lastPaymentDate,
    nextPaymentDate,
    currentBalance,
    customerName,
    lastFour,
    // wordsUsed,
    subscription,
}: BillingMetadataProps) {
    const stripeMetadata = useStripeMetadata();
    const billingStatus = useBillingStatus();

    if (!stripeMetadata) return null;

    return (
        <QuiBox>
            <table className={styles.billingMetadataTable}>
                <tbody>
                    {subscription && typeof billingStatus === 'string' ? (
                        <MetadataRow label="Subscription" content={SUBSCRIPTION_LABELS[billingStatus]} />
                    ) : null}

                    {lastFour && typeof stripeMetadata?.lastFour === 'string' ? (
                        <MetadataRow label="Card on file" content={stripeMetadata.lastFour} />
                    ) : null}

                    {customerName && typeof stripeMetadata?.customerName === 'string' ? (
                        <MetadataRow label="Name on card" content={stripeMetadata.customerName} />
                    ) : null}

                    {lastPaymentDate && typeof stripeMetadata?.lastPaymentDate === 'string' ? (
                        <MetadataRow label="Last Payment Date" content={formatBillingDate(new Date(stripeMetadata.lastPaymentDate))} />
                    ) : null}

                    {nextPaymentDate && typeof stripeMetadata?.nextPaymentDate === 'string' ? (
                        <MetadataRow label="Next Payment Date" content={formatBillingDate(new Date(stripeMetadata.nextPaymentDate))} />
                    ) : null}

                    {currentBalance && typeof stripeMetadata.currentBalance === 'number' ? (
                        <MetadataRow label="Current Balance" content={formatCurrency(stripeMetadata.currentBalance)} />
                    ) : null}
                </tbody>
            </table>
        </QuiBox>
    );
}
