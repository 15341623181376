import { FileUploadPipeline } from './FileUploadPipeline';
import { AmazonS3Pipeline } from './AmazonS3Pipeline';
import { usePipelineContext } from '../../components/PipelineLayout/usePipelineContext';
import { QuiBox } from '@tonicai/ui-quinine';

export function ParseJob() {
    const { parseJobConfig } = usePipelineContext();

    if (parseJobConfig.useInternalBucket) {
        return (
            <QuiBox padding="md">
                <FileUploadPipeline />
            </QuiBox>
        );
    }

    return (
        <QuiBox padding="md">
            <AmazonS3Pipeline />
        </QuiBox>
    );
}
