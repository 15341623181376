// These are inconsistent across the codebase, so we need to keep both for now

export enum ObjectStorageType {
    Local = 0,
    S3 = 1,
    Databricks = 2,
}

export enum ObjectStorageTypeStringEnum {
    Local = 'Local',
    S3 = 'S3',
    Databricks = 'Databricks',
}
