import { HelpDocumentation } from '@/components/HelpDocumentation/HelpDocumentation';
import { PageTitle } from '@/components/PageTitle/PageTitle';
import { QuiBox, QuiFlexBoxColumn, QuiFlexH, QuiText } from '@tonicai/ui-quinine';
import { SubscriptionBadge } from './SubscriptionBadge';
import { useBillingStatus, useHasActivePlan, useLifetimeUsage } from '@/stores';
import { ManagePaymentButton } from './ManagePaymentButton';
import { CloudSubscriptionStatus } from '@/types';
import { UpgradePlanButton } from './UpgradePlanButton';
import { LearnMoreButton } from './LearnMoreButton';
import { BillingMetadata } from './BillingMetadata';
import { formatWordCount } from './utils';

export function WordCountLeftSidebar() {
    const billingStatus = useBillingStatus();
    const hasActivePlan = useHasActivePlan();
    const usage = useLifetimeUsage();
    return (
        <>
            <QuiBox display="flex" alignItems="start" flexDirection="column" gap="md" padding="none lg none none">
                <PageTitle icon="flag" title="Manage Plan" />
            </QuiBox>
            <QuiFlexBoxColumn gap="md" justifyContent="space-between">
                <QuiBox borderRadius="md" border="border-default" padding="lg" margin="lg none none none">
                    <QuiFlexBoxColumn gap="sm">
                        {billingStatus ? (
                            <QuiBox>
                                <SubscriptionBadge status={billingStatus} />
                            </QuiBox>
                        ) : null}

                        {hasActivePlan ? (
                            <QuiText size="text-lg" weight="bold">
                                {`${formatWordCount(usage)} words`}
                            </QuiText>
                        ) : (
                            <QuiText size="text-lg" weight="bold">
                                {`${formatWordCount(usage)}`}
                                <QuiBox weight="normal" color="text-secondary" display="inline">
                                    &nbsp;/ 100,000 words
                                </QuiBox>
                            </QuiText>
                        )}

                        <BillingMetadata />

                        <QuiBox margin="lg none none none">
                            <QuiFlexH gap="sm">
                                {billingStatus === CloudSubscriptionStatus.Active ? (
                                    <ManagePaymentButton />
                                ) : (
                                    <>
                                        <UpgradePlanButton />
                                        <LearnMoreButton />
                                    </>
                                )}
                            </QuiFlexH>
                        </QuiBox>
                    </QuiFlexBoxColumn>
                </QuiBox>
                <HelpDocumentation />
            </QuiFlexBoxColumn>
        </>
    );
}
