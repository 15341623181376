import { FileDetails } from './FileDetails';
import { FileIcon } from '../FileIcon/FileIcon';
import { getFileNameFromUrl } from '../../utils';
import { QuiBox } from '@tonicai/ui-quinine';
import { FileAdderFileItemMenu } from './FileAdderFileItemMenu';
import { useDatasetFile } from '../../stores/datasetFiles';

type FileAdderFileRowProps = Readonly<{
    fileId: string;
    datasetId: string;
}>;

export function FileAdderFileItem({ fileId, datasetId }: FileAdderFileRowProps) {
    const file = useDatasetFile(fileId);

    if (!file) return null;

    return (
        <QuiBox
            bg="white"
            borderRadius="md"
            border="white-600"
            padding={'sm'}
            display={'flex'}
            flexDirection={'row'}
            gap={'xs'}
            justifyContent={'space-between'}
            alignItems={'center'}
        >
            <QuiBox display="flex" flexGrow="1" alignItems="center" gap="sm" overflow="hidden">
                <div>
                    <FileIcon jobStatus={file.processingStatus} />
                </div>
                <QuiBox display="flex" flexDirection="column" flexGrow="1" overflow="hidden">
                    <QuiBox text="mono-text-sm" textOverflow="ellipsis" whiteSpace="nowrap" overflow="hidden">
                        {file.fileSource == 'Local' ? file.fileName : `${getFileNameFromUrl(file.fileName)} (${file.fileSource})`}
                    </QuiBox>
                    <QuiBox color="black-600" text="text-xs" textOverflow="ellipsis" whiteSpace="nowrap" overflow="hidden">
                        <FileDetails
                            jobStatus={file.processingStatus}
                            processingError={file.processingError}
                            numRows={file.numRows}
                            wordCount={file.wordCount}
                        />
                    </QuiBox>
                </QuiBox>
            </QuiBox>
            <QuiBox display="flex" gap="sm" flexShrink="0" alignItems="center">
                <FileAdderFileItemMenu datasetId={datasetId} file={file} />
            </QuiBox>
        </QuiBox>
    );
}
