import { z } from 'zod';

export type Guid = string & { __guid: never };

// This is a frontend-specific type definition to make life easier, there's no backend equivalent
export type CurrentUser = {
    displayName?: string | null;
    userName?: string | null;
    id?: string | null;
    photoURL: string | null;
    createdDate: null | Date;
    lastActivityDate: null | Date;
};

// Keep in sync with:
// backend/Solar.Api/Models/UserPhotoModel.cs

export const UserPhotoModelSchema = z.object({
    name: z.string(),
    url: z.string().optional().nullable(),
    fileType: z.string().optional().nullable(),
    content: z.unknown().optional().nullable(),
    isManualUpload: z.boolean(),
});

export type UserPhotoModel = z.infer<typeof UserPhotoModelSchema>;

// Keep in sync with:
// backend/Solar.Api/Models/UserEntityModel.cs

export const UserEntityModelSchema = z.object({
    id: z.string().optional(),
    userName: z.string().optional().nullable(),
    firstName: z.string().optional().nullable(),
    lastName: z.string().optional().nullable(),
    lastActivityDate: z.string().optional().nullable(),
    // The time the user was created, not the token
    createdAt: z.string(),
    photoUpload: UserPhotoModelSchema.optional().nullable(),
});

export type UserEntityModel = z.infer<typeof UserEntityModelSchema>;

// Keep in sync with:
// backend/Solar.Api/Models/LoginAndRefreshTokenResponseModel.cs
export const LoginAndRefreshTokenResponseModelSchema = z.object({
    user: UserEntityModelSchema.optional().nullable(),
    jwt: z.string().optional(),
    expiresIn: z.number(),
    refreshToken: z.string(),
    refreshTokenRefreshIntervalInSeconds: z.number(),
    inactivityTimeoutInSeconds: z.number(),
    environmentName: z.string().optional().nullable(),
    createdDate: z.string(),
    amplitudeApiKey: z.string().optional().nullable(),
    amplitudeApiUrl: z.string().optional().nullable(),
});

export type LoginAndRefreshTokenResponseModel = z.infer<typeof LoginAndRefreshTokenResponseModelSchema>;

// Keep in sync with:
// backend/Solar.Api/Enums/SsoProvider.cs
export enum SsoProvider {
    google = 'Google',
    github = 'GitHub',
    okta = 'Okta',
    azure = 'Azure',
    oidc = 'OIDC',
}

export type SsoSettingsResponse = {
    isRequired: boolean;
    settings: SsoSettingsFromApi[];
};

// Keep in sync with:
// backend/Solar.Api/Models/SsoSettings.cs
export type SsoSettingsFromApi = {
    enabled: boolean;
    provider: SsoProvider;
    clientId?: string;
    domain?: string;
    identityProviderId?: string;
    authorizationServerId?: string;
    canUseAsExternalFileSource: boolean;
};

export type EnabledSSOConfig = {
    provider: SsoProvider;
    clientId: string;
    domain?: string;
    identityProviderId?: string;
    authorizationServerId?: string;
    canUseAsExternalFileSource: boolean;
};

export type SSOSignInConfig = {
    token: string;
    provider: SsoProvider;
    originalRedirectUrl: string;
    parameters: Record<string, string | null>;
};

export type SignInFormValues = {
    userName: string;
    password: string;
};

export type SignUpFormValues = {
    email: string;
    password: string;
    confirmPassword: string;
};

export type SSOCallbackData = {
    token: string;
    provider: SsoProvider;
    searchParams: Record<string, string | null>;
    type: 'code';
};

export type SSOErrorData = {
    error: string | null;
    errorDescription: string | null;
    type: 'error';
};

export type SignUpSignInError = {
    login_failure: string[];
};

export type ResetPasswordRequestModel = {
    token: string;
    userName: string;
    newPassword: string;
};

export type ResetPasswordRequest = {
    userId: Guid;
    token: string;
    password: string;
    email: string;
};

export type ForgotPasswordRequest = {
    emailAddress: string;
    token: string;
    isRetry: boolean;
};

/**
 * External Credentials
 */
export type ExternalCredential = {
    credentialType: ExternalCredentialType;
    name: string;
    value: string;
};

export enum ExternalCredentialType {
    OpenAIKey = 'OpenAIKey',
    GoogleServiceAccount = 'GoogleServiceAccount',
}
