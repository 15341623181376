import { QuiFlexBoxColumn } from '@tonicai/ui-quinine';
import { PageTitle } from '../../components/PageTitle/PageTitle';
import { FilesTableLocal } from '../FileParseJob/FilesTableLocal';

export function FileUploadPipeline() {
    return (
        <QuiFlexBoxColumn gap="md">
            <PageTitle icon="list" title="Files" />
            <FilesTableLocal />
        </QuiFlexBoxColumn>
    );
}
