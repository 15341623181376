import { z } from 'zod';
import { FileSource, FileSourceEnum, FileSourceStringEnum, FileType } from './datasets';
import { JobType } from './jobs';
import { PiiTypeEnum } from './pii-types';
import { ObjectStorageType, ObjectStorageTypeStringEnum } from './storage';

export const AwsCredentialSchema = z
    .object({
        accessKey: z
            .string({
                message: 'Access Key is required',
            })
            .trim()
            .min(1, {
                message: 'Access Key is required',
            }),
        secretKey: z
            .string({
                message: 'Secret Key is required',
            })
            .trim()
            .min(1, {
                message: 'Secret Key is required',
            }),
        region: z
            .string({
                message: 'Region is required',
            })
            .trim()
            .min(1, {
                message: 'Region is required',
            }),
        sessionToken: z.string().optional(),
    })
    .strict();

export type AwsCredential = z.infer<typeof AwsCredentialSchema>;

export const DatabricksCredentialSchema = z
    .object({
        url: z
            .string({
                required_error: 'URL is required',
            })
            .url('Invalid URL format'),
        accessToken: z
            .string({
                required_error: 'Access Token is required',
            })
            .min(1, 'Access Token cannot be empty'),
    })
    .strict();

export type DatabricksCredential = z.infer<typeof DatabricksCredentialSchema>;

export type ParseJobExternalCredentialRequestModel = {
    fileSource: FileSourceEnum;
    credential: AwsCredential;
};

export enum ParseJobTransferModeRescanOptionsEnum {
    DoNotRescan = 0,
    RescanAdded = 1,
    RescanChanged = 2,
}

export enum ParseJobTriggerEnum {
    RunOnDemand = 'RunOnDemand',
    RunOnSchedule = 'RunOnSchedule',
}

export enum AwsCredentialSourceEnum {
    UserProvided = 'UserProvided',
    FromEnvironment = 'FromEnvironment',
}

export type ParseJobExternalCredentialApiModel = {
    fileSource: FileSourceEnum;
    credential: AwsCredential | DatabricksCredential;
};

export type DatabricksConnectionDetails = {
    url: string;
    accessToken: string;
};

export type ParseJobConfigResponse = {
    id: string;
    userId?: string;
    name: string;
    objectStorageType: ObjectStorageType;
    fileSource: FileSourceStringEnum;
    parseJobExternalCredential?: ParseJobExternalCredentialApiModel;
    selectedFileExtensions: string[];
    selectedFiles: string[];
    pathPrefixes: string[];
    createdDate: string;
    lastModifiedDate: string;
    outputPath: string;
    useInternalBucket: boolean;
    synthesizeFiles: boolean;
    awsCredentialSource: AwsCredentialSourceEnum;
    databricksConnectionDetails: DatabricksConnectionDetails;
};

export type UpdateParseJobConfigRequest = Omit<
    ParseJobConfigRequest,
    'id' | 'userId' | 'createdDate' | 'updatedDate' | 'fileSource' | 'objectStorageType' | 'useInternalBucket' | 'awsCredentialSource'
>;

const JOB_STATUS_STRINGS = ['Running', 'Queued', 'Canceled', 'Completed', 'Failed', 'Skipped'] as const;

export const jobStatusStringSchema = z.enum(JOB_STATUS_STRINGS).default('Queued');

export type JobStatusString = z.infer<typeof jobStatusStringSchema>;

export type FileParseJobResponse = {
    id: string;
    jobType: JobType;
    startTime: string;
    endTime: string;
    errorMessages?: string;
    status: JobStatusString;
    createdDate: string;
    lastModifiedDate: string;
};

export type ParseJobScheduleOptionsApiModel = {
    trigger: ParseJobTriggerEnum;
    executionStartDate?: string;
    executionEndDate?: string;
    executionInterval?: string;
};

export type ParseJobConfigRequest = {
    name: string;
    objectStorageType: ObjectStorageType;
    fileSource: FileSourceEnum;
    parseJobExternalCredential?: ParseJobExternalCredentialApiModel;
    selectedFileExtensions: string[];
    selectedFiles: string[];
    pathPrefixes: string[];
    outputPath: string;
    synthesizeFiles: boolean;
    awsCredentialSource: AwsCredentialSourceEnum;
    databricksConnectionDetails?: DatabricksConnectionDetails;
};

export type BaseFileApiModel = {
    id: string;
    userId?: string;
    oid: number;
    fileSizeInKb: number;
    fileName: string;
    escapeChar: string;
    quoteChar: string;
    hasHeader: boolean;
    delimiter: string;
    nullChar: string;
    numRows?: number;
    fileType: FileType;
    columnCount: number;
    piiTypeCounts: number;
    wordCount: number;
    characterCount: number;
    redactedWordCount: number;
    piiTypes: PiiTypeEnum[];
    piiTypeExamples: string;
    createdDate: string;
    lastModifiedDate: string;
    fileHash: string;
    fileSource: FileSource;
    filePath?: string;
};

export type FileParseResultApiModel = {
    id: string;
    file: BaseFileApiModel;
    fileParseJobId: string;
    parsedFilePath: string;
    createdDate: string;
    lastModifiedDate: string;
    fileStatus: JobStatusString;
};

export type FilesParsedResponse = {
    files: FileParseResultApiModel[];
    continuationToken?: number;
};

export const singleDetectionResultSchema = z
    .object({
        start: z.number(),
        end: z.number(),
        label: z.string(),
        text: z.string(),
        score: z.number(),
        jsonPath: z.string().optional().nullable(),
    })
    .strict();

export type SingleDetectionResult = z.infer<typeof singleDetectionResultSchema>;

export const redactedFromApiResponseModelSchema = z
    .object({
        originalText: z.string(),
        redactedText: z.string(),
        usage: z.number(),
        deIdentifyResults: z.array(singleDetectionResultSchema),
    })
    .strict();

export type RedactedFromApiResponseModel = z.infer<typeof redactedFromApiResponseModelSchema>;

export type CreateLocalParseJobConfigRequest = {
    name: string;
    fileSource: FileSourceEnum.Local;
    objectStorageType: ObjectStorageTypeStringEnum.Local;
};

export type CreateS3ParseJobConfigRequest =
    | {
          name: string;
          fileSource: FileSourceEnum.Aws;
          objectStorageType: ObjectStorageType.S3;
          outputPath: string;
          awsCredentialSource: AwsCredentialSourceEnum.UserProvided;
          parseJobExternalCredential: {
              fileSource: FileSourceEnum.Aws | FileSourceEnum.Databricks;
              credential: AwsCredential | DatabricksCredential;
          };
      }
    | {
          name: string;
          fileSource: FileSourceEnum.Aws;
          objectStorageType: ObjectStorageType.S3;
          outputPath: string;
          awsCredentialSource: AwsCredentialSourceEnum.FromEnvironment;
          parseJobExternalCredential: {
              fileSource: FileSourceEnum.Aws;
          };
      };

export interface CreateDatabricksParseJobConfigRequest {
    name: string;
    fileSource: FileSourceEnum.Databricks;
    objectStorageType: ObjectStorageType.Databricks;
    parseJobExternalCredential: {
        fileSource: FileSourceEnum.Aws | FileSourceEnum.Databricks;
        credential: AwsCredential | DatabricksCredential;
    };
}

export type CreateParseJobConfigRequest = CreateLocalParseJobConfigRequest | CreateS3ParseJobConfigRequest | CreateDatabricksParseJobConfigRequest;

export const SUPPORTED_PARSE_JOB_FILE_EXTENSIONS = ['.docx', '.xlsx', '.pdf', '.png', '.tiff', '.tif', '.jpg', '.jpeg', '.txt', '.csv', '.tsv'];

export type SolarCsvConfig = {
    numColumns: number;
    hasHeader: boolean;
    escapeChar: string;
    quoteChar: string;
    delimiter: string;
    nullChar: string;
};

export type LocalParseFileUploadRequestModel = {
    fileName: string;
    csvConfig: Partial<SolarCsvConfig>;
};

export type UploadedFile = {
    fileName: string;
    result?: FileParseResultApiModel;
};

export type UploadedFilesResponse = {
    files?: UploadedFile[];
    continuationToken?: string;
};
