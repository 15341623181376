import { QuiIcon, QuiIconEnum } from '@tonicai/ui-quinine';
import { JobStatus } from '../../types';
import styles from './FileIcon.module.scss';

type FileIconProps = Readonly<{ jobStatus: JobStatus }>;

export function FileIcon({ jobStatus }: FileIconProps) {
    if (jobStatus === JobStatus.QUEUED) {
        return <QuiIcon size="sm" icon={QuiIconEnum.Clock} />;
    }

    if (jobStatus === JobStatus.RUNNING) {
        return <QuiIcon className={styles.spinning} size="sm" icon={QuiIconEnum.RefreshCw} />;
    }

    if (jobStatus === JobStatus.FAILED || jobStatus === JobStatus.CANCELED) {
        return <QuiIcon size="sm" icon={QuiIconEnum.AlertTriangle} style={{ color: 'red' }} />;
    }

    // JobStatus.COMPLETED fallback
    return <QuiIcon size="sm" icon={QuiIconEnum.File} />;
}
