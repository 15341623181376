import { QuiBox, QuiGridLayout, QuiFlexH, QuiFlexV, QuiCell, QuiText, QuiSpinner } from '@tonicai/ui-quinine';
import BarChart from './BarChart';
import { useEffect } from 'react';
import { fetchBillingInfo, useBillingStatus, useFetchedBillingData, useLast30DaysUsage } from '@/stores';
import { WordCountRightSidebar } from './WordCountRightSidebar';
import { WordCountLeftSidebar } from './WordCountLeftSidebar';
import { CloudSubscriptionStatus } from '@/types';
import { Navigate } from 'react-router-dom';
import styles from './Billing.module.scss';
import { formatWordCount } from './utils';

export function Billing() {
    const last30daysUsage = useLast30DaysUsage();
    const billingStatus = useBillingStatus();
    const fetchedBillingData = useFetchedBillingData();

    useEffect(() => {
        fetchBillingInfo();
    }, []);

    if (!fetchedBillingData) {
        return (
            <QuiBox display="flex" justifyContent="center" alignItems="center" padding="md">
                <QuiSpinner size="sm" />
            </QuiBox>
        );
    }

    // Redirect to home if the user doesn't have an active subscription
    if (billingStatus === CloudSubscriptionStatus.None) {
        return <Navigate to="/" />;
    }

    return (
        <QuiBox className={styles.wrapper} maxWidth="wide" padding="md">
            <QuiGridLayout gap="md">
                <QuiCell width={3}>
                    <WordCountLeftSidebar />
                </QuiCell>
                <QuiCell width={6}>
                    <QuiBox padding="lg" bg="background-primary" border="border-default" borderRadius="md">
                        <QuiFlexV gap="md">
                            <QuiFlexH align="bottom" justify="between">
                                <QuiText>Usage last 30 days</QuiText>
                                <QuiText color="text-secondary" size="text-xs">
                                    {`${formatWordCount(last30daysUsage)} words`}
                                </QuiText>
                            </QuiFlexH>
                            <BarChart />
                        </QuiFlexV>
                    </QuiBox>
                </QuiCell>
                <QuiCell width={3}>
                    <WordCountRightSidebar />
                </QuiCell>
            </QuiGridLayout>
        </QuiBox>
    );
}
