import { useCallback, useEffect, useMemo, useRef } from 'react';
import { Document, Page } from 'react-pdf';
import { PDFDocumentProxy } from 'pdfjs-dist';
import {
    PdfDimensions,
    PdfRedaction,
    PdfRedactionArea,
    PdfAndImageRedactionSource,
    PdfRedactionType,
    PdfWord,
    PiiTypeGeneratorState,
    Rectangle,
    FileType,
} from '../../types';
import { getAddedDrawnAreas, getPiiTypeGeneratorStateOrDefault } from './PdfPageCalculationUtils';
import {
    drawAutomaticRedactions,
    drawCurrentBox,
    drawManualRedactions,
    drawNewWordOutlines,
    resizeCanvas,
    scalePdfRedaction,
    scaleRectangle,
} from './PdfPageDrawingUtils';
import styles from './PdfPage.module.scss';
import { useAtomValue } from 'jotai';
import { dprAtom } from '../../stores/ui';
import { ScalableImage } from './ScalableImage';

type PdfPageProps = Readonly<{
    pdfBlob: string;
    pageIndex: number;
    isEditable: boolean;
    redactions?: PdfRedaction[];
    pdfWords?: PdfWord[];
    onNewAreaAdded?: (area: PdfRedactionArea) => void;
    newAreaType?: PdfRedactionType;
    generatorSetup?: Record<string, PiiTypeGeneratorState>;
    isPreview: boolean;
    newRedactionSource: PdfAndImageRedactionSource;
    pdfScale?: number;
    onDocumentLoadSuccess?: (doc: PDFDocumentProxy) => void;
    fileType: FileType;
}>;

export function PdfPage({
    pdfBlob,
    pageIndex,
    redactions,
    pdfWords,
    generatorSetup,
    isPreview,
    newAreaType,
    isEditable,
    newRedactionSource,
    pdfScale,
    onNewAreaAdded,
    onDocumentLoadSuccess,
    fileType,
}: PdfPageProps) {
    // Monitor the device pixel ratio to ensure the canvas is always the correct size
    // This can change if the user zooms in or out
    const dpr = useAtomValue(dprAtom);

    // Set these on every render rather than via useEffect because useEffect runs after the render
    const onNewAreaAddedRef = useRef(onNewAreaAdded);
    const pageNumberRef = useRef(pageIndex + 1);
    const newAreaTypeRef = useRef(newAreaType);

    useEffect(() => {
        onNewAreaAddedRef.current = onNewAreaAdded;
    }, [onNewAreaAdded]);

    useEffect(() => {
        pageNumberRef.current = pageIndex + 1;
    }, [pageIndex]);

    useEffect(() => {
        newAreaTypeRef.current = newAreaType;
    }, [newAreaType]);

    const words = useMemo(() => {
        return [...(pdfWords ?? [])].filter((w) => w.pageNumber === pageIndex + 1);
    }, [pdfWords, pageIndex]);

    const currentPageRedactions = useMemo(() => {
        return (
            redactions
                ?.filter((redaction) => getPiiTypeGeneratorStateOrDefault(redaction.piiTypeLabel, generatorSetup) !== 'Off')
                .map((redaction) => {
                    return {
                        ...redaction,
                        areas: redaction.areas.filter((area) => area.pageNumber === pageIndex + 1),
                    };
                })
                .filter((redaction) => redaction.areas.length > 0) ?? []
        );
    }, [redactions, pageIndex, generatorSetup]);

    const automaticJobRedactions = useMemo(() => {
        return currentPageRedactions?.filter((d) => d.source === PdfAndImageRedactionSource.DetectedDuringJob) ?? [];
    }, [currentPageRedactions]);

    const removingRedactions = useMemo(() => {
        return currentPageRedactions.filter((d) => d.type === PdfRedactionType.RemoveRedaction);
    }, [currentPageRedactions]);

    const addingRedactions = useMemo(() => {
        return currentPageRedactions.filter((d) => d.type === PdfRedactionType.AddRedaction);
    }, [currentPageRedactions]);

    const newWordOutlines = useMemo(() => {
        return addingRedactions.map((d) => {
            const areas = d.areas.flatMap((area) => getAddedDrawnAreas(area, words));
            return {
                ...d,
                areas,
            };
        });
    }, [addingRedactions, words]);

    const pageDimensionsRef = useRef<PdfDimensions>({ height: 0, width: 0 });
    const animationRequestRef = useRef<number | null>(null);
    const containerRef = useRef<HTMLDivElement>(null);
    const canvasRef = useRef<HTMLCanvasElement>(null);

    const automaticJobRedactionsRef = useRef(automaticJobRedactions);
    const removingRedactionsRef = useRef(removingRedactions);
    const addingRedactionsRef = useRef(addingRedactions);
    const newWordOutlinesRef = useRef(newWordOutlines);

    const mousePositionRef = useRef<{ x: number; y: number } | null>(null);
    const mouseStartPositionRef = useRef<{ x: number; y: number } | null>(null);

    useEffect(() => {
        automaticJobRedactionsRef.current = automaticJobRedactions;
        removingRedactionsRef.current = removingRedactions;
        addingRedactionsRef.current = addingRedactions;
        newWordOutlinesRef.current = newWordOutlines;
        newAreaTypeRef.current = newAreaType;
    }, [automaticJobRedactions, removingRedactions, addingRedactions, newWordOutlines, newAreaType]);

    const handleMouseEvents = useCallback((e: MouseEvent) => {
        if (!canvasRef.current) return;

        const canvasRect = canvasRef.current.getBoundingClientRect();
        const relativeX = (e.clientX - canvasRect.left) / canvasRect.width;
        const relativeY = (e.clientY - canvasRect.top) / canvasRect.height;

        const x = relativeX * pageDimensionsRef.current.width;
        const y = relativeY * pageDimensionsRef.current.height;

        mousePositionRef.current = { x, y };

        if (e.type === 'mousedown') {
            mouseStartPositionRef.current = { x, y };
        } else if (e.type === 'mouseup') {
            if (mouseStartPositionRef.current && onNewAreaAddedRef.current) {
                const xStart = Math.min(mouseStartPositionRef.current.x, x);
                const xEnd = Math.max(mouseStartPositionRef.current.x, x);
                const yStart = Math.min(mouseStartPositionRef.current.y, y);
                const yEnd = Math.max(mouseStartPositionRef.current.y, y);

                onNewAreaAddedRef.current({
                    x: xStart,
                    y: yStart,
                    width: xEnd - xStart,
                    height: yEnd - yStart,
                    pageNumber: pageNumberRef.current,
                });
            }
            mouseStartPositionRef.current = null;
        }
    }, []);

    useEffect(() => {
        const ctx = canvasRef.current?.getContext('2d');

        if (!ctx) return;

        const draw = () => {
            ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);

            const canvasRect = ctx.canvas.getBoundingClientRect();

            let drawnRectangle: Rectangle | undefined = undefined;
            if (mouseStartPositionRef.current && mousePositionRef.current) {
                const xStart = Math.min(mouseStartPositionRef.current.x, mousePositionRef.current.x);
                const xEnd = Math.max(mouseStartPositionRef.current.x, mousePositionRef.current.x);
                const yStart = Math.min(mouseStartPositionRef.current.y, mousePositionRef.current.y);
                const yEnd = Math.max(mouseStartPositionRef.current.y, mousePositionRef.current.y);

                const rect: Rectangle = {
                    x: xStart,
                    y: yStart,
                    width: xEnd - xStart,
                    height: yEnd - yStart,
                };
                const scaledRectangle = scaleRectangle(rect, pageDimensionsRef.current, canvasRect);
                drawnRectangle = scaledRectangle;
                drawCurrentBox(ctx, scaledRectangle, newAreaTypeRef.current ?? PdfRedactionType.RemoveRedaction);
            }

            drawAutomaticRedactions(
                ctx,
                automaticJobRedactionsRef.current.map((redaction) => scalePdfRedaction(redaction, pageDimensionsRef.current, canvasRect)),
                removingRedactionsRef.current.map((redaction) => scalePdfRedaction(redaction, pageDimensionsRef.current, canvasRect)),
                drawnRectangle,
                newAreaTypeRef.current,
                isPreview,
                generatorSetup
            );

            drawNewWordOutlines(
                ctx,
                newWordOutlinesRef.current.map((redaction) => scalePdfRedaction(redaction, pageDimensionsRef.current, canvasRect)),
                words,
                isPreview,
                newAreaTypeRef.current,
                generatorSetup,
                drawnRectangle
            );

            drawManualRedactions(
                ctx,
                [...addingRedactionsRef.current, ...removingRedactionsRef.current].map((redaction) =>
                    scalePdfRedaction(redaction, pageDimensionsRef.current, canvasRect)
                ),
                newRedactionSource,
                isPreview
            );

            animationRequestRef.current = window.requestAnimationFrame(draw);
        };

        animationRequestRef.current = window.requestAnimationFrame(draw);

        return () => {
            const req = animationRequestRef.current;
            if (req !== null) {
                window.cancelAnimationFrame(req);
            }
        };
    }, [isEditable, isPreview, generatorSetup, pageIndex, words, onNewAreaAdded, newRedactionSource]);

    useEffect(() => {
        // Don't listen to events if the page is not editable
        if (!isEditable) return;

        const canvas = canvasRef.current;

        if (!canvas) return;

        const abortController = new AbortController();

        canvas.addEventListener('mousedown', handleMouseEvents, { signal: abortController.signal });
        canvas.addEventListener('mouseup', handleMouseEvents, { signal: abortController.signal });
        canvas.addEventListener('mousemove', handleMouseEvents, { signal: abortController.signal });
        canvas.addEventListener('mouseenter', handleMouseEvents, { signal: abortController.signal });

        return () => {
            abortController.abort();
        };
    }, [isEditable, handleMouseEvents]);

    // Resize the canvas whenever the DPR changes
    useEffect(() => {
        resizeCanvas(containerRef.current, canvasRef.current);
    }, [dpr]);

    // Resize the canvas whenever the container changes sizes
    useEffect(() => {
        const container = containerRef.current;
        const canvas = canvasRef.current;

        if (!container || !canvas) return;

        const resizeObserver = new ResizeObserver(() => {
            resizeCanvas(containerRef.current, canvasRef.current);
        });

        resizeObserver.observe(container);

        return () => {
            resizeObserver.unobserve(container);
        };
    }, [dpr]);

    const onLoadSuccess = useCallback((page: { originalHeight: number; originalWidth: number }) => {
        pageDimensionsRef.current = { height: page.originalHeight, width: page.originalWidth };
    }, []);

    const onImageLoad = useCallback((dimensions: { width: number; height: number }) => {
        pageDimensionsRef.current = dimensions;
    }, []);

    const scale = typeof pdfScale === 'number' ? pdfScale : 1;

    return (
        <div ref={containerRef} className={styles.container}>
            {fileType === 'Pdf' && (
                <Document file={pdfBlob} onLoadSuccess={onDocumentLoadSuccess}>
                    <Page
                        pageNumber={pageIndex + 1}
                        renderAnnotationLayer={false}
                        renderTextLayer={false}
                        scale={scale}
                        onLoadSuccess={onLoadSuccess}
                    />
                </Document>
            )}
            {fileType !== 'Pdf' && <ScalableImage scale={scale} src={pdfBlob} onLoad={onImageLoad} />}
            <canvas ref={canvasRef} className={styles.canvas} />
        </div>
    );
}
