import { QuiMenu, QuiMenuButton, QuiMenuItem, QuiMenuItems, useQuiModal } from '@tonicai/ui-quinine';
import { Dataset } from '../../types';
import { DeleteDatasetModal } from '../../components/DeleteDatasetModal/DeleteDatasetModal';
import { EditDatasetNameModal } from '../../components/EditDatasetNameModal/EditDatasetNameModal';

type DatasetInfoProps = Readonly<{
    dataset: Dataset;
}>;

export function DatasetInfoMenu({ dataset }: DatasetInfoProps) {
    const deleteDatasetModal = useQuiModal();
    const editDatasetNameModal = useQuiModal();

    return (
        <>
            <QuiMenu>
                <QuiMenuButton size="sm" iconLeft="settings">
                    Settings
                </QuiMenuButton>
                <QuiMenuItems>
                    <QuiMenuItem iconLeft="edit" onClick={editDatasetNameModal.open}>
                        Edit Dataset Name
                    </QuiMenuItem>
                    <QuiMenuItem iconLeft="trash" onClick={deleteDatasetModal.open}>
                        Delete Dataset
                    </QuiMenuItem>
                </QuiMenuItems>
            </QuiMenu>
            <DeleteDatasetModal isOpen={deleteDatasetModal.isOpen} onClose={deleteDatasetModal.close} dataset={dataset} />
            <EditDatasetNameModal isOpen={editDatasetNameModal.isOpen} onClose={editDatasetNameModal.close} dataset={dataset} />
        </>
    );
}
