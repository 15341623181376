import { QuiButton } from '@tonicai/ui-quinine';
import { openPlanDialog } from '@/stores';

type LearnMoreButtonProps = Readonly<{
    onClick?: () => void;
}>;

export function LearnMoreButton({ onClick }: LearnMoreButtonProps) {
    return (
        <QuiButton
            onClick={() => {
                onClick?.();
                openPlanDialog();
            }}
            type="button"
        >
            Learn More
        </QuiButton>
    );
}
