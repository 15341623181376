import { QuiBadge, QuiBox, QuiButton, QuiText } from '@tonicai/ui-quinine';
import dayjs from 'dayjs';
import { useAtomValue } from 'jotai';
import { useMemo } from 'react';
import { FileAdder } from '../../components/FileAdder/FileAdder';
import { DatasetTemplatesButton } from '../../components/Templates/DatasetTemplatesButton';
import { currentUserAtom } from '../../stores/auth';
import { useIsOcrEnabled } from '../../stores/settings';
import { Dataset } from '../../types';
import { useFilePreviewContext } from '../../components/FilePreviews/useFilePreviewContext';
import styles from './DatasetInfo.module.scss';
import { DatasetInfoMenu } from './DatasetInfoMenu';

type DatasetInfoProps = Readonly<{
    dataset: Dataset;
}>;

export function DatasetInfo({ dataset }: DatasetInfoProps) {
    const { openPreview } = useFilePreviewContext();
    const user = useAtomValue(currentUserAtom);
    const ocrEnabled = useIsOcrEnabled();

    const hasAtLeastOnePDF = useMemo(() => {
        return dataset.files.find((f) => f.fileType === 'Pdf') !== undefined;
    }, [dataset.files]);

    return (
        <QuiBox overflow="hidden" borderRadius="md" border="white-600">
            <QuiBox bg="white" borderBottom="white-600" padding="md" gap="md" flexDirection="column" display="flex">
                <QuiBox display="flex" alignItems="center" gap="sm">
                    <QuiBadge iconLeft="message-square" size="xl" variant="purple" />
                    <QuiText size="text-xl" weight="bold">
                        {dataset.name}
                    </QuiText>
                </QuiBox>
                <QuiBox display="flex" flexDirection="column" gap="sm">
                    <div>
                        <QuiBox text="text-xs" color="black-600">
                            {`Edited ${dayjs(dataset.lastUpdated).fromNow()} by ${user?.displayName ?? user?.userName}`}
                        </QuiBox>
                    </div>
                </QuiBox>
                {ocrEnabled && hasAtLeastOnePDF && (
                    <QuiBox>
                        <DatasetTemplatesButton datasetId={dataset.id} />
                    </QuiBox>
                )}
                <QuiBox display="flex" flexDirection={'column'} gap="sm">
                    <QuiBox display="flex" gap="sm">
                        <DatasetInfoMenu dataset={dataset} />
                    </QuiBox>
                </QuiBox>
            </QuiBox>

            <QuiBox padding="md" display="flex" gap="sm" flexDirection="column">
                <QuiBox display="flex" alignItems="stretch">
                    <QuiButton className={styles.fullWidth} iconLeft="folder" type="button" onClick={openPreview}>
                        Preview and Manage Files
                    </QuiButton>
                </QuiBox>
                <FileAdder mode="combobox" name="fileIds" datasetId={dataset.id} />
            </QuiBox>
        </QuiBox>
    );
}
