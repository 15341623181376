import { QuiBox, QuiButton, QuiFlexBoxColumn } from '@tonicai/ui-quinine';
import { PiiTypeEnum, PiiTypeGeneratorState } from '../../types';
import { useForm } from 'react-final-form';
import { useCallback } from 'react';
import { GeneratorConfig } from './GeneratorConfig';

type GeneratorFieldsProps = Readonly<{
    generatorStatesName: string;
    generatorMetadataName: string;
}>;

export function GeneratorFields({ generatorStatesName, generatorMetadataName }: GeneratorFieldsProps) {
    const form = useForm();

    const bulkApplySetting = useCallback(
        (piiTypeGeneratorState: PiiTypeGeneratorState) => {
            form.batch(() => {
                Object.entries(PiiTypeEnum).forEach(([, value]) => {
                    form.change(`${generatorStatesName}.${value}`, piiTypeGeneratorState);
                });
            });
        },
        [generatorStatesName, form]
    );

    return (
        <QuiFlexBoxColumn gap="sm">
            <QuiBox
                bg="background-primary"
                border="border-default"
                padding="sm"
                alignItems="center"
                borderRadius="md"
                display="flex"
                justifyContent="space-between"
            >
                <QuiBox text="text-sm" weight="medium">
                    Bulk Edit
                </QuiBox>

                <QuiBox display="flex" gap="xs">
                    <QuiButton
                        size="sm"
                        type="button"
                        onClick={() => {
                            bulkApplySetting('Synthesis');
                        }}
                    >
                        Synthesize
                    </QuiButton>
                    <QuiButton
                        size="sm"
                        type="button"
                        onClick={() => {
                            bulkApplySetting('Redaction');
                        }}
                    >
                        Redact
                    </QuiButton>
                    <QuiButton
                        size="sm"
                        type="button"
                        onClick={() => {
                            bulkApplySetting('Off');
                        }}
                    >
                        Off
                    </QuiButton>
                </QuiBox>
            </QuiBox>

            <QuiFlexBoxColumn gap="xs" border="border-default" overflow="hidden" borderRadius="md" bg="background-primary">
                {Object.entries(PiiTypeEnum).map(([, value], index, list) => (
                    <QuiBox key={value} borderBottom={index < list.length - 1 ? 'border-default' : undefined}>
                        <GeneratorConfig
                            metadataName={`${generatorMetadataName}.${value}`}
                            piiType={value}
                            generatorStatusName={`${generatorStatesName}.${value}`}
                        />
                    </QuiBox>
                ))}
            </QuiFlexBoxColumn>
        </QuiFlexBoxColumn>
    );
}
