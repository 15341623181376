import { QuiBox, QuiButton } from '@tonicai/ui-quinine';
import { fetchDatasets } from '../../stores/datasets';
import { useEffect, useState } from 'react';
import { CreateDatasetDialog } from '../../components/CreateDatasetDialog/CreateDatasetDialog';
import styles from './Datasets.module.scss';
import { PageTitle } from '../../components/PageTitle/PageTitle';
import { DATASETS_DESCRIPTION } from '../../constants';
import { PipelinesDatasetsTable } from '@/components/PipelinesDatasetsTable/PipelinesDatasetsTable';

export function Datasets() {
    const [isCreateDialogOpen, setIsCreateDialogOpen] = useState(false);

    useEffect(() => {
        fetchDatasets();
    }, []);

    return (
        <>
            <div className={styles.layout}>
                <div>
                    <QuiBox flexDirection="column" display="flex" gap="md">
                        <PageTitle icon="database" title="Datasets">
                            {DATASETS_DESCRIPTION}
                        </PageTitle>
                        <QuiBox>
                            <QuiButton
                                variant="brand-purple"
                                onClick={() => {
                                    setIsCreateDialogOpen(true);
                                }}
                            >
                                Create a Dataset
                            </QuiButton>
                        </QuiBox>
                    </QuiBox>
                </div>
                <div className={styles.main}>
                    <PipelinesDatasetsTable excludePipelines />
                </div>
            </div>
            <CreateDatasetDialog
                isOpen={isCreateDialogOpen}
                onClose={() => {
                    setIsCreateDialogOpen(false);
                }}
            />
        </>
    );
}
