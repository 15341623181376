import { QuiBox, QuiCheckbox, QuiIcon, QuiTooltip } from '@tonicai/ui-quinine';
import classNames from 'classnames';
import { useCallback, useMemo } from 'react';
import DatabricksCatalog from '../icons/databricks-catalog.svg?react';
import DatabricksSchema from '../icons/databricks-schema.svg?react';
import DatabricksVolume from '../icons/databricks-volume.svg?react';
import S3Bucket from '../icons/s3-bucket.svg?react';
import { useCloudFileExplorer } from './CloudFileExplorer';
import styles from './CloudFileExplorerRow.module.scss';
import { isCloudStorageItemSelected } from './utils';

type CloudFileExplorerRowProps = {
    type: 'Bucket' | 'Catalog' | 'Schema' | 'Volume' | 'Folder' | 'File';
    name: string;
    isSelectable: boolean;
};

function CloudItemIcon({ type }: { type: CloudFileExplorerRowProps['type'] }) {
    switch (type) {
        case 'Bucket':
            return <S3Bucket height={16} />;
        case 'Catalog':
            return <DatabricksCatalog height={16} />;
        case 'Schema':
            return <DatabricksSchema height={16} />;
        case 'Volume':
            return <DatabricksVolume height={16} />;
        case 'Folder':
            return <QuiIcon icon="folder" />;
        case 'File':
            return <QuiIcon icon="file" />;
        default:
            return null;
    }
}

export function CloudFileExplorerRow({ type, name, isSelectable }: CloudFileExplorerRowProps) {
    const { currentPath, selectItem, pathPrefixes, selectedFiles, onFileSelected, onPathSelected, disableNestedPaths, allowMultipleSelection } =
        useCloudFileExplorer();

    const itemPath = useMemo(() => (currentPath ? `${currentPath}/${name}` : name), [name, currentPath]);

    const hasSubDirectorySelected = useMemo(() => {
        if (type === 'File') return false;

        const currentNamePath = `${itemPath}/`;

        return (
            pathPrefixes.some((p) => p.startsWith(currentNamePath) && p !== currentNamePath && p !== currentPath) ||
            selectedFiles.some((f) => f.startsWith(currentNamePath))
        );
    }, [pathPrefixes, selectedFiles, itemPath, type, currentPath]);

    const isCurrentPathMatched = useMemo(
        () => disableNestedPaths && pathPrefixes.some((p) => itemPath.startsWith(`${p}/`) && p !== itemPath),
        [disableNestedPaths, pathPrefixes, itemPath]
    );

    const isChecked = useMemo(
        () =>
            isCloudStorageItemSelected({
                itemPath,
                selectedFiles,
                pathPrefixes,
                type,
                disableNestedPaths,
                allowFileSelection: isSelectable,
            }),
        [itemPath, selectedFiles, pathPrefixes, type, disableNestedPaths, isSelectable]
    );

    const onCheck = useCallback(() => {
        if (type === 'File') {
            onFileSelected(itemPath);
        } else {
            onPathSelected(itemPath);
        }
    }, [type, itemPath, onFileSelected, onPathSelected]);

    const onSelect = useCallback(() => {
        if (type !== 'File') {
            selectItem(name);
        }
    }, [type, name, selectItem]);

    const disabled = useMemo(() => {
        return isCurrentPathMatched || !isSelectable;
    }, [isCurrentPathMatched, isSelectable]);

    return (
        <QuiBox display="flex" padding="none sm none none" alignItems="center">
            <QuiTooltip disabled={!isCurrentPathMatched} content="Matched by a path prefix" placement="top">
                {allowMultipleSelection ? (
                    <QuiCheckbox
                        className={!isSelectable ? styles.hidden : undefined}
                        disabled={disabled}
                        padding="sm"
                        checked={isChecked || hasSubDirectorySelected}
                        onChange={onCheck}
                        indeterminate={hasSubDirectorySelected && !isChecked}
                    />
                ) : (
                    <input
                        className={classNames(styles.radioInput, {
                            [`${styles.hidden}`]: !isSelectable,
                        })}
                        type="radio"
                        checked={isChecked}
                        onChange={onCheck}
                        disabled={disabled}
                    />
                )}
            </QuiTooltip>

            <button
                className={classNames(styles.nameButton, {
                    [styles.nameButtonDisabled]: disabled,
                })}
                type="button"
                disabled={type === 'File'}
                onClick={onSelect}
            >
                <CloudItemIcon type={type} />
                <span>{name}</span>
            </button>
        </QuiBox>
    );
}
