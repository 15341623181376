import { QuiFlexBoxColumn, QuiIcon } from '@tonicai/ui-quinine';
import { Outlet, useMatch } from 'react-router-dom';
import { PipelineBreadcrumbs } from './PipelineBreadcrumbs';
import { ParseJobConfigResponse } from '../../types';
import { useEffect, useMemo } from 'react';
import { fetchParseJobConfigFileParseJobs, fetchParseJobConfigFiles, useParseJobConfigHasQueuedOrActiveParseJobs } from '../../stores/parse-jobs';
import { PipelineSidebar } from './PipelineSidebar';
import { FileUploadPipelineProvider } from '../../contexts/FileUploadPipeline/FileUploadPipelineProvider';
import { PipelineContext, PipelineContextValue } from './PipelineContext';
import styles from './PipelineLayout.module.scss';
import classNames from 'classnames';
import { NextSteps } from './NextSteps';
import { atomWithStorage } from 'jotai/utils';
import { globalStore } from '../../stores/globalStore';
import { useAtomValue } from 'jotai';

const nextStepsDrawerOpenAtom = atomWithStorage('nextStepsDrawerOpen', true);

function canRunPipelineJob(parseJobConfig: ParseJobConfigResponse) {
    if (parseJobConfig.useInternalBucket) return true;

    if (!parseJobConfig.outputPath || parseJobConfig.outputPath.trim().length === 0) return false;

    return parseJobConfig.selectedFiles.length > 0 || parseJobConfig.pathPrefixes.length > 0;
}

function toggleNextStepsDrawer() {
    globalStore.set(nextStepsDrawerOpenAtom, (prev) => !prev);
}

type PipelineLayoutProps = Readonly<{
    parseJobConfig: ParseJobConfigResponse;
    parseJobConfigId: string;
    refetchParseJobConfig: () => Promise<unknown>;
}>;

export function PipelineLayout({ parseJobConfig, refetchParseJobConfig, parseJobConfigId }: PipelineLayoutProps) {
    const hasActiveOrQueuedJob = useParseJobConfigHasQueuedOrActiveParseJobs(parseJobConfigId);
    const nextStepsDrawerOpen = useAtomValue(nextStepsDrawerOpenAtom, { store: globalStore });

    const match = useMatch('/pipelines/:parseJobConfigId');

    // Poll for active or queued jobs so that the UI can update their status
    // without a page refresh
    useEffect(() => {
        if (hasActiveOrQueuedJob === false) return;

        const interval = window.setInterval(() => {
            fetchParseJobConfigFileParseJobs(parseJobConfigId);
            fetchParseJobConfigFiles(parseJobConfigId);
        }, 2000);

        return () => {
            window.clearTimeout(interval);
        };
    }, [hasActiveOrQueuedJob, parseJobConfigId]);

    const contextValue = useMemo<PipelineContextValue>(() => {
        return {
            parseJobConfig,
            parseJobConfigId,
            refetchParseJobConfig,
            canRunPipelineJob: canRunPipelineJob(parseJobConfig),
        };
    }, [parseJobConfig, refetchParseJobConfig, parseJobConfigId]);

    return (
        <PipelineContext.Provider value={contextValue}>
            {/** FileUploadPipelineProvider only used by "file upload"/"internal bucket" pipelines */}
            <FileUploadPipelineProvider>
                <div className={styles.wrapper}>
                    <div className={styles.sidebar}>
                        <PipelineSidebar />
                    </div>
                    <div
                        className={classNames(styles.main, {
                            [styles.mainNextStepsOpen]: nextStepsDrawerOpen && match !== null,
                        })}
                    >
                        <QuiFlexBoxColumn>
                            <PipelineBreadcrumbs />

                            <div>
                                <Outlet />
                            </div>
                        </QuiFlexBoxColumn>
                    </div>

                    {match !== null ? (
                        <>
                            <div
                                className={classNames(styles.nextSteps, {
                                    [styles.nextStepsOpen]: nextStepsDrawerOpen,
                                })}
                            >
                                {nextStepsDrawerOpen ? <NextSteps /> : null}
                            </div>
                            <button
                                className={classNames(styles.nextStepsButton, {
                                    [styles.nextStepsButtonOpen]: nextStepsDrawerOpen,
                                })}
                                type="button"
                                onClick={toggleNextStepsDrawer}
                            >
                                {nextStepsDrawerOpen ? (
                                    <QuiIcon icon="chevrons-right" />
                                ) : (
                                    <>
                                        <QuiIcon icon="chevrons-left" />
                                        <span>Next Steps</span>
                                    </>
                                )}
                            </button>
                        </>
                    ) : null}
                </div>
            </FileUploadPipelineProvider>
        </PipelineContext.Provider>
    );
}
