import { useFilePreview } from '../../hooks/useFilePreview';
import { TextFilePreviewTable } from './TextFilePreviewTable';
import { QuiBox, QuiSpinner } from '@tonicai/ui-quinine';
import { DatasetFile, JobStatus } from '../../types';
import styles from './TextFilePreview.module.scss';
import { Message } from '../Message/Message';

type FilePreviewProps = Readonly<{
    datasetId: string;
    file: DatasetFile;
}>;

function Preview({ datasetId, file }: FilePreviewProps) {
    const { originalDataStatus, deidentifiedDataStatus, originalHtml, deidentifiedHtml, fileName, fileType } = useFilePreview(file.fileId, datasetId);

    return (
        <div className={styles.row}>
            <div className={styles.column}>
                {originalHtml && (originalDataStatus === 'success' || originalDataStatus === 'refreshing') ? (
                    <TextFilePreviewTable fileName={fileName} filePreviewHtml={originalHtml} fileType={fileType} />
                ) : null}

                {originalDataStatus === 'loading' && (
                    <QuiBox justifyContent="center" padding="md">
                        <QuiSpinner />
                    </QuiBox>
                )}
            </div>
            <div className={styles.column}>
                {deidentifiedHtml && (deidentifiedDataStatus === 'success' || deidentifiedDataStatus === 'refreshing') ? (
                    <TextFilePreviewTable fileName={fileName} filePreviewHtml={deidentifiedHtml} fileType={fileType} />
                ) : null}

                {deidentifiedDataStatus === 'loading' ? (
                    <QuiBox justifyContent="center" padding="md">
                        <QuiSpinner />
                    </QuiBox>
                ) : null}
            </div>
        </div>
    );
}

export function TextFilePreview({ datasetId, file }: FilePreviewProps) {
    // return <div>{file.processingStatus}</div>;
    if (file.processingStatus === JobStatus.COMPLETED) {
        return <Preview datasetId={datasetId} file={file} />;
    }

    if (file.processingStatus === JobStatus.QUEUED || file.processingStatus === JobStatus.RUNNING) {
        return (
            <QuiBox padding="md">
                <Message variant="info">File Processing Status: {file.processingStatus}</Message>
            </QuiBox>
        );
    }

    return (
        <QuiBox padding="md">
            <Message variant="error">
                File Processing Failed: {file.processingStatus} {file.processingError ?? 'Unknown Error'}
            </Message>
        </QuiBox>
    );
}
