export function getPipelineCodeSnippet(pipelineId: string) {
    return `from tonic_textual.parse_api import TonicTextualParse

textual = TonicTextualParse("${window.location.protocol}//${window.location.host}")

pipeline = textual.get_pipeline_by_id("${pipelineId}")

#DEFINE ENTITIES INTERESTING FOR RAG
metadata_entities = [
    "ORGANIZATION"
]

#DEFINE SENSITIVE ENTITIES YOU WISH TO REDACT/SYNTHESIZE PRIOR TO CHUNKING
sensitive_entities = [
    "NAME_GIVEN",
    "NAME_FAMILY",
    "EMAIL_ADDRESS",
    "PHONE_NUMBER",
    "CREDIT_CARD",
    "CC_EXP",
    "CVV",
    "US_BANK_NUMBER"
]

generator_config = {label: 'Redaction' for label in sensitive_entities}

#GENERATE CHUNKS USING OUR CHUNKING ALGORITHM. CHUNKS WILL INCLUDE THE INTERESTING METDATA AND ALL SENSITIVE ENTITIES WILL BE REDACTED/SYNTHESIZED
chunks=[]
for file in pipeline.enumerate_files():
    chunks = file.get_chunks(generator_config=generator_config,metadata_entities=metadata_entities)`;
}
