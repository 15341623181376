import { DatasetFile, JobStatus } from '../../types';
import { QuiTooltip } from '@tonicai/ui-quinine';

function getCountWithUnits(count: number) {
    if (typeof count !== 'number') return '';
    if (count <= 9999) return count;
    if (count >= 10000 && count <= 999999) return (count / 1000).toFixed(1) + 'K';
    return (count / 1000000).toFixed(1) + 'M';
}

type FileDetailsProps = Readonly<{
    jobStatus: JobStatus;
    numRows: DatasetFile['numRows'];
    wordCount: DatasetFile['wordCount'];
    processingError: DatasetFile['processingError'];
}>;

export function FileDetails({ jobStatus, numRows, wordCount, processingError }: FileDetailsProps) {
    // Failure
    if (jobStatus == JobStatus.FAILED) {
        return processingError ? (
            <QuiTooltip content={<span>{processingError}</span>}>
                <span>{processingError}</span>
            </QuiTooltip>
        ) : (
            <span>Error</span>
        );
    }

    // Cancelled
    if (jobStatus === JobStatus.CANCELED) {
        return <span>Cancelled</span>;
    }

    // Running
    if (jobStatus === JobStatus.RUNNING) {
        return <span>Processing...</span>;
    }

    // Queued
    if (jobStatus === JobStatus.QUEUED) {
        return <span>Queued...</span>;
    }

    const infoStrings: string[] = [];

    if (typeof numRows === 'number' && numRows > 0) {
        const rowOrRows = numRows === 1 ? 'row' : 'rows';
        infoStrings.push(`${getCountWithUnits(numRows)} ${rowOrRows}`);
    }

    if (typeof wordCount === 'number' && wordCount > 0) {
        const wordOrWords = wordCount === 1 ? 'word' : 'words';
        infoStrings.push(`${getCountWithUnits(wordCount)} ${wordOrWords}`);
    }

    // Completed: Details about data (if available)
    if (infoStrings.length > 0) {
        return infoStrings.join(', ');
    }

    return null;
}
