export enum CloudSubscriptionStatus {
    None = 'None',
    Expired = 'Expired',
    Active = 'Active',
}

export const FREE_TRIAL_WORD_COUNT = 100_000;

export const PRICE_PER_1000_WORDS = 0.89;

export type TotalUsageHistoryResponseModel = {
    wordCount: number;
    earliestJobDate: string | undefined | null;
};

export type StripeAccountMetadata = {
    lastPaymentDate?: string | null;
    nextPaymentDate?: string | null;
    currentBalance?: number | null;
    customerName?: string | null;
    lastFour?: string | null;
    wordsUsed?: number | null;
};
