import {
    QuiBox,
    QuiButton,
    QuiConditionalField,
    QuiField,
    QuiFlexBoxColumn,
    QuiForm,
    QuiFormProps,
    QuiModalContent,
    QuiModalDialog,
    QuiPasswordField,
    QuiSegmentedControlButton,
    QuiSegmentedControlField,
    QuiSelectField,
    QuiSubmitButton,
    QuiText,
    QuiTextField,
} from '@tonicai/ui-quinine';
import { AxiosResponse, isCancel } from 'axios';
import isEqual from 'fast-deep-equal';
import { FORM_ERROR } from 'final-form';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { FormSpy, useField, useFormState } from 'react-final-form';
import { Navigate } from 'react-router-dom';
import { client } from '../../services/HTTPClient';
import { createParseJob } from '../../stores/parse-jobs';
import { useAreAwsCredsAvailable, useIsHostedProd } from '../../stores/settings';
import {
    AWS_S3_REGIONS_OPTIONS,
    AwsCredential,
    AwsCredentialSourceEnum,
    CreateDatabricksParseJobConfigRequest,
    CreateParseJobConfigRequest,
    DatabricksCredential,
    FileSourceEnum,
    ObjectStorageType,
    ParseJobConfigResponse,
} from '../../types';
import { getAwsCredentialsError, isAwsCredentialsError } from '../../utils';
import { requiredString } from '../../validation';
import { Message } from '../Message/Message';
import styles from './CreatePipelineDialog.module.scss';
import { SourceType } from './SourceType';

type FormState = {
    name: string;
    fileSource: SourceType;
    useEnvironmentCredentials: boolean;
    awsAccessKey: string;
    awsAccessSecret: string;
    awsRegion: string;
    awsSessionToken: string;
    databricksUrl: string;
    databricksAccessToken: string;
};

const INITIAL_VALUES: FormState = {
    name: '',
    fileSource: SourceType.Files,
    useEnvironmentCredentials: false,
    awsAccessKey: '',
    awsAccessSecret: '',
    awsRegion: '',
    awsSessionToken: '',
    databricksUrl: '',
    databricksAccessToken: '',
};

type FormContentProps = Readonly<{
    onClose: () => void;
    errorToShow: 'form' | 'test' | 'none';
    setErrorToShow: (errorToShow: 'form' | 'test' | 'none') => void;
}>;

function convertToString(value: unknown) {
    try {
        return String(value);
    } catch (e) {
        return '';
    }
}

function parseDatabricksUrl(url: unknown) {
    const urlString = convertToString(url);
    if (urlString === '') return urlString;

    if (urlString.startsWith('https://')) {
        return urlString;
    }
    if (urlString.startsWith('http://')) {
        return urlString.slice(7);
    }
    return `https://${urlString}`;
}

function formatDatabricksUrl(url: unknown) {
    // Convert to string and if we can't then return empty string
    const urlString = convertToString(url);
    if (urlString === '') return urlString;

    if (urlString.startsWith('https://')) {
        return urlString.slice(8);
    }
    if (urlString.startsWith('http://')) {
        return urlString.slice(7);
    }
    return urlString;
}

function FormContent({ onClose, errorToShow, setErrorToShow }: FormContentProps) {
    const databricksUrlField = useField('databricksUrl');
    const databricksAccessTokenField = useField('databricksAccessToken');

    const [databricksTestStatus, setDatabricksTestStatus] = useState<'untested' | 'testing' | 'success' | 'error'>('untested');
    const [databricksTestErrorMessage, setDatabricksTestErrorMessage] = useState<string | null>(null);

    const databricksTestConnectionEnabled = useMemo(() => {
        return databricksUrlField.meta.valid && databricksAccessTokenField.meta.valid;
    }, [databricksUrlField.meta.valid, databricksAccessTokenField.meta.valid]);

    useEffect(() => {
        setDatabricksTestStatus('untested');
    }, [databricksUrlField.input.value, databricksAccessTokenField.input.value]);

    const testDatabricksConnection = useCallback(() => {
        setErrorToShow('none');

        const data: DatabricksCredential = {
            url: databricksUrlField.input.value,
            accessToken: databricksAccessTokenField.input.value,
        };

        abortControllerRef.current?.abort();
        abortControllerRef.current = new AbortController();

        setDatabricksTestErrorMessage(null);
        setDatabricksTestStatus('testing');
        client
            .post<{ success: boolean; errorMessage: string | null }>(
                `/api/cloudfile/test-cloud-connection`,
                {
                    fileSource: FileSourceEnum.Databricks,
                    credential: data,
                },
                { signal: abortControllerRef.current.signal }
            )
            .then((response) => {
                setErrorToShow('test');
                if (!response.data.success || typeof response.data.errorMessage === 'string') {
                    setDatabricksTestStatus('error');
                    setDatabricksTestErrorMessage(response.data.errorMessage);
                } else {
                    setDatabricksTestStatus('success');
                    setDatabricksTestErrorMessage(null);
                }
            })
            .catch((error) => {
                if (!isCancel(error)) {
                    console.error(error);
                    setDatabricksTestStatus('error');
                    setErrorToShow('test');
                }
            });
    }, [databricksUrlField.input.value, databricksAccessTokenField.input.value, setErrorToShow]);

    const areAwsCredsAvailable = useAreAwsCredsAvailable();
    const isHostedProd = useIsHostedProd();
    const showCredsSourceToggle = !isHostedProd && areAwsCredsAvailable;

    const { submitting, submitError, values } = useFormState({
        subscription: {
            submitting: true,
            submitError: true,
            values: true,
        },
    });

    const awsKeyField = useField('awsAccessKey');
    const awsAccessSecretField = useField('awsAccessSecret');
    const awsRegionField = useField('awsRegion');
    const awsSessionToken = useField('awsSessionToken');

    const lastCheckedValues = useRef<AwsCredential | null>(null);
    const abortControllerRef = useRef<AbortController | null>(null);

    const [testStatus, setTestStatus] = useState<'untested' | 'testing' | 'success' | 'error'>('untested');
    const [testErrorMessage, setTestErrorMessage] = useState<string | null>(null);

    const testConnectionEnabled = useMemo(() => {
        return awsKeyField.meta.valid && awsAccessSecretField.meta.valid && awsRegionField.meta.valid;
    }, [awsKeyField, awsAccessSecretField, awsRegionField]);

    useEffect(() => {
        setTestStatus('untested');
    }, [awsKeyField.input.value, awsAccessSecretField.input.value, awsRegionField.input.value, awsSessionToken.input.value]);

    useEffect(() => {
        if (submitting) {
            setTestStatus('untested');
        }
    }, [submitting]);

    useEffect(() => {
        setErrorToShow('none');
    }, [values, setErrorToShow]);

    const testAWSConnection = useCallback(
        (automatic?: boolean) => {
            setErrorToShow('none');

            const data: AwsCredential = {
                accessKey: awsKeyField.input.value,
                secretKey: awsAccessSecretField.input.value,
                region: awsRegionField.input.value,
                sessionToken: awsSessionToken.input.value,
            };

            if (automatic && lastCheckedValues.current !== null && isEqual(data, lastCheckedValues.current)) {
                return;
            }

            abortControllerRef.current?.abort();
            abortControllerRef.current = new AbortController();

            lastCheckedValues.current = data;

            setTestErrorMessage(null);
            setTestStatus('testing');
            client
                .post<{ success: boolean; errorMessage: string | null }>(
                    `/api/cloudfile/test-cloud-connection`,
                    {
                        fileSource: FileSourceEnum.Aws,
                        credential: data,
                    },
                    { signal: abortControllerRef.current.signal }
                )
                .then((response) => {
                    setErrorToShow('test');
                    if (!response.data.success || typeof response.data.errorMessage === 'string') {
                        setTestStatus('error');
                        setTestErrorMessage(response.data.errorMessage);
                    } else {
                        setTestStatus('success');
                        setTestErrorMessage(null);
                    }
                })
                .catch((error) => {
                    if (!isCancel(error)) {
                        console.error(error);
                        setTestStatus('error');
                        setErrorToShow('test');
                    }
                });
        },
        [awsKeyField, awsAccessSecretField, awsRegionField, awsSessionToken, setErrorToShow]
    );

    useEffect(() => {
        return () => {
            abortControllerRef.current?.abort();
        };
    }, []);

    return (
        <QuiFlexBoxColumn gap="md">
            <QuiTextField data-test="pipeline-name-input" validate={requiredString} size="sm" label="Name" name="name" />

            <QuiField label="Files Source">
                <QuiSegmentedControlField<SourceType> size="sm" name="fileSource">
                    <QuiSegmentedControlButton<SourceType> iconLeft="upload" value={SourceType.Files}>
                        File Upload
                    </QuiSegmentedControlButton>
                    <QuiSegmentedControlButton<SourceType> iconLeft="cloud" value={SourceType.Aws}>
                        Amazon S3
                    </QuiSegmentedControlButton>
                    <QuiSegmentedControlButton<SourceType> iconLeft="box" value={SourceType.Databricks}>
                        Databricks
                    </QuiSegmentedControlButton>
                </QuiSegmentedControlField>
            </QuiField>

            {showCredsSourceToggle ? (
                <QuiConditionalField comparision="equal" fieldName="fileSource" value={SourceType.Aws}>
                    <QuiField label="AWS Credentials Location">
                        <QuiSegmentedControlField<boolean> size="sm" name="useEnvironmentCredentials">
                            <QuiSegmentedControlButton<boolean> value={false}>User</QuiSegmentedControlButton>
                            <QuiSegmentedControlButton<boolean> value={true}>Environment</QuiSegmentedControlButton>
                        </QuiSegmentedControlField>
                    </QuiField>
                </QuiConditionalField>
            ) : null}

            <QuiConditionalField comparision="equal" fieldName="fileSource" value={SourceType.Aws}>
                <QuiConditionalField comparision="equal" fieldName="useEnvironmentCredentials" value={true}>
                    <QuiBox text="text-sm" color="text-secondary">
                        Credentials are sourced from the environment.
                    </QuiBox>
                </QuiConditionalField>

                <QuiConditionalField comparision="equal" fieldName="useEnvironmentCredentials" value={false}>
                    <QuiFlexBoxColumn className={styles.awsCredentials} gap="md">
                        <QuiBox weight="medium" text="text-sm">
                            Amazon S3 Credentials
                        </QuiBox>

                        <QuiTextField size="sm" validate={requiredString} label="Access Key" name="awsAccessKey" id="aws-access-key" />

                        <QuiPasswordField size="sm" validate={requiredString} label="Secret Access Key" name="awsAccessSecret" id="aws-secret-key" />

                        <QuiSelectField
                            validate={requiredString}
                            label="Region"
                            name="awsRegion"
                            id="aws-region"
                            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                            // @ts-ignore
                            size="sm"
                            options={AWS_S3_REGIONS_OPTIONS}
                            menuPortalTarget={document.body}
                        />

                        <QuiPasswordField size="sm" label="Session Token" name="awsSessionToken" id="aws-session-token" />

                        <QuiButton
                            spinner={testStatus === 'testing'}
                            iconRight="globe"
                            type="button"
                            size="sm"
                            disabled={!testConnectionEnabled || testStatus === 'testing' || submitting}
                            onClick={() => {
                                testAWSConnection();
                            }}
                        >
                            Test AWS Connection
                        </QuiButton>

                        {/* Success Message */}
                        {errorToShow === 'test' && testStatus === 'success' ? (
                            <Message variant="success">Successfully connected to AWS</Message>
                        ) : null}

                        {/* Error Message */}
                        {errorToShow === 'test' && testStatus === 'error' ? (
                            <Message variant="error">{testErrorMessage ?? 'Could not connect to AWS'}</Message>
                        ) : null}
                    </QuiFlexBoxColumn>
                </QuiConditionalField>
            </QuiConditionalField>

            <QuiConditionalField comparision="equal" fieldName="fileSource" value={SourceType.Databricks}>
                <QuiFlexBoxColumn className={styles.databricksCredentials} gap="md">
                    <QuiBox weight="medium" text="text-sm">
                        Databricks Credentials
                    </QuiBox>

                    <QuiTextField
                        size="sm"
                        validate={requiredString}
                        iconLeft={<QuiText weight="bold">https://</QuiText>}
                        placeholder="your-workspace.cloud.databricks.com"
                        label="Databricks URL"
                        name="databricksUrl"
                        config={{ parse: parseDatabricksUrl, format: formatDatabricksUrl }}
                        id="databricks-workspace-url"
                    />

                    <QuiPasswordField
                        size="sm"
                        validate={requiredString}
                        label="Access Token"
                        name="databricksAccessToken"
                        id="databricks-access-token"
                    />

                    <QuiButton
                        spinner={databricksTestStatus === 'testing'}
                        iconRight="globe"
                        type="button"
                        size="sm"
                        disabled={!databricksTestConnectionEnabled || databricksTestStatus === 'testing' || submitting}
                        onClick={testDatabricksConnection}
                    >
                        Test Databricks Connection
                    </QuiButton>

                    {/* Success Message */}
                    {errorToShow === 'test' && databricksTestStatus === 'success' ? (
                        <Message variant="success">Successfully connected to Databricks</Message>
                    ) : null}

                    {/* Error Message */}
                    {errorToShow === 'test' && databricksTestStatus === 'error' ? (
                        <Message variant="error">{databricksTestErrorMessage ?? 'Could not connect to Databricks'}</Message>
                    ) : null}
                </QuiFlexBoxColumn>
            </QuiConditionalField>

            {errorToShow === 'form' && submitError ? <Message variant="error">{submitError}</Message> : null}

            <QuiBox display="flex" gap="md" alignItems="center">
                <QuiSubmitButton data-test="create-dialog-save-button" variant="brand-purple">
                    Save
                </QuiSubmitButton>
                <QuiButton onClick={onClose} type="button">
                    Cancel
                </QuiButton>
            </QuiBox>
        </QuiFlexBoxColumn>
    );
}

function formSubmitErrorHandler(error: unknown) {
    if (isAwsCredentialsError(error)) {
        return {
            [FORM_ERROR]: getAwsCredentialsError(error) ?? 'Invalid AWS credentials.',
        };
    }
    return {
        [FORM_ERROR]: 'Unable to create Pipeline.',
    };
}

type CreatePipelineDialogProps = Readonly<{
    onClose: () => void;
    isOpen: boolean;
}>;

export function CreatePipelineDialog({ isOpen, onClose }: CreatePipelineDialogProps) {
    const [newPipeline, setNewPipeline] = useState<ParseJobConfigResponse | null>(null);

    const [errorToShow, setErrorToShow] = useState<'form' | 'test' | 'none'>('none');

    function SubmitFilesPipeline(values: FormState) {
        return client
            .post<ParseJobConfigResponse, AxiosResponse<ParseJobConfigResponse>, { name: string }>('/api/parsejobconfig/local-files', {
                name: values.name,
            })
            .then(({ data }) => {
                setNewPipeline(data);
                return undefined;
            })
            .catch(formSubmitErrorHandler);
    }

    function SubmitAwsPipeline(values: FormState) {
        // Create Amazon S3 Pipelines That Uses Environment Credentials
        if (values.useEnvironmentCredentials) {
            const requestData: CreateParseJobConfigRequest = {
                name: values.name,
                outputPath: '',
                awsCredentialSource: AwsCredentialSourceEnum.FromEnvironment,
                objectStorageType: ObjectStorageType.S3,
                fileSource: FileSourceEnum.Aws,
                parseJobExternalCredential: {
                    fileSource: FileSourceEnum.Aws,
                },
            };
            return createParseJob(requestData)
                .then((pipeline) => {
                    setNewPipeline(pipeline);
                    return undefined;
                })
                .catch(formSubmitErrorHandler);
        } else {
            // Create Amazon S3 Pipelines That Uses User Provided Credentials
            const requestData: CreateParseJobConfigRequest = {
                name: values.name,
                awsCredentialSource: AwsCredentialSourceEnum.UserProvided,
                objectStorageType: ObjectStorageType.S3,
                fileSource: FileSourceEnum.Aws,
                outputPath: '',
                parseJobExternalCredential: {
                    fileSource: FileSourceEnum.Aws,
                    credential: {
                        accessKey: values.awsAccessKey,
                        secretKey: values.awsAccessSecret,
                        region: values.awsRegion,
                        sessionToken: values.awsSessionToken,
                    },
                },
            };
            return createParseJob(requestData)
                .then((pipeline) => {
                    setNewPipeline(pipeline);
                    return undefined;
                })
                .catch(formSubmitErrorHandler);
        }
    }

    async function SubmitDatabricksPipeline(values: FormState) {
        if (!values.databricksUrl || !values.databricksAccessToken) {
            return { [FORM_ERROR]: 'Databricks URL and access token are required.' };
        }

        const requestData: CreateDatabricksParseJobConfigRequest = {
            name: values.name,
            objectStorageType: ObjectStorageType.Databricks,
            fileSource: FileSourceEnum.Databricks,
            parseJobExternalCredential: {
                fileSource: FileSourceEnum.Databricks,
                credential: {
                    url: values.databricksUrl,
                    accessToken: values.databricksAccessToken,
                },
            },
        };

        return createParseJob(requestData)
            .then((pipeline) => {
                setNewPipeline(pipeline);
                return undefined;
            })
            .catch(formSubmitErrorHandler);
    }

    const onSubmit = useCallback<QuiFormProps<FormState>['onSubmit']>(async (values) => {
        setErrorToShow('form');

        switch (values.fileSource) {
            case SourceType.Files:
                return SubmitFilesPipeline(values);
            case SourceType.Aws:
                return SubmitAwsPipeline(values);
            case SourceType.Databricks:
                return SubmitDatabricksPipeline(values);
            default:
                return { [FORM_ERROR]: 'Invalid file source selected.' };
        }
    }, []);

    if (newPipeline?.useInternalBucket === true) {
        return <Navigate to={`/pipelines/${newPipeline.id}`} />;
    }

    if (newPipeline) {
        return <Navigate to={`/pipelines/${newPipeline.id}/settings`} />;
    }

    return (
        <QuiModalDialog className={styles.modal} isOpen={isOpen} onClose={onClose}>
            <QuiModalContent style={{ width: '400px' }}>
                <QuiFlexBoxColumn gap="md">
                    <QuiText size="text-lg" weight="medium">
                        Create A New Pipeline
                    </QuiText>
                    <QuiForm<FormState> initialValues={INITIAL_VALUES} onSubmit={onSubmit}>
                        <FormSpy>{({ error }) => <div>{error}</div>}</FormSpy>
                        <FormContent errorToShow={errorToShow} setErrorToShow={setErrorToShow} onClose={onClose} />
                    </QuiForm>
                </QuiFlexBoxColumn>
            </QuiModalContent>
        </QuiModalDialog>
    );
}
