import { usePdfOrImageFileData } from '../../hooks/usePdfOrImageFileData';
import { DatasetFile, PdfAndImageRedactionSource } from '../../types';
import { useScale } from '../Templates/useScale';
import { ZoomButtons } from '../ZoomButtons/ZoomButtons';
import { PdfPage } from '../PdfPage/PdfPage';
import { useTemplate } from '../../hooks/useTemplate';
import { memo, useEffect, useMemo } from 'react';
import { useDataset } from '../../stores/datasets';
import { fetchFileBlob, useFileBlob } from '../../stores/fileBlobs';
import { useFilePreviewContext } from './useFilePreviewContext';
import styles from './PdfFilePreview.module.scss';
import equal from 'fast-deep-equal';
import { QuiSpinner } from '@tonicai/ui-quinine';
import { Message } from '../Message/Message';

type PdfFilePreviewDialogProps = Readonly<{
    file: DatasetFile;
    datasetId: string;
}>;

export const PdfFilePreview = memo(function PdfFilePreview({ file, datasetId }: PdfFilePreviewDialogProps) {
    const pdfFileData = usePdfOrImageFileData(file.datasetId, file.fileId);
    const { template } = useTemplate(file.datasetId, pdfFileData?.fileTemplateId);
    const dataset = useDataset(datasetId);
    const fileBlob = useFileBlob(file.fileId);
    const { pageNumber } = useFilePreviewContext();

    useEffect(() => {
        fetchFileBlob(datasetId, file.fileId);
    }, [datasetId, file.fileId]);

    const redactions = useMemo(() => {
        return [...(pdfFileData?.fileSpecificRedactions ?? []), ...(pdfFileData?.automaticJobRedactions ?? []), ...(template?.redactions ?? [])];
    }, [pdfFileData, template?.redactions]);

    const scale = useScale();

    if (fileBlob.fetchStatus === 'loading' || fileBlob.fetchStatus === 'init') return <QuiSpinner />;

    if (fileBlob.fetchStatus === 'error') return <Message variant="error">{`Failed to load ${file.fileType.toUpperCase()}`}</Message>;

    if (fileBlob.fetchStatus === 'success') {
        return (
            <>
                <div className={styles.column}>
                    <div className={styles.columnInner}>
                        <PdfPage
                            pdfBlob={fileBlob.blob}
                            pageIndex={pageNumber}
                            isEditable={false}
                            isPreview={true}
                            redactions={redactions}
                            pdfWords={pdfFileData?.pdfWords}
                            newRedactionSource={PdfAndImageRedactionSource.AttachedToFile}
                            generatorSetup={dataset?.generatorSetup}
                            pdfScale={scale.scale}
                            fileType={file.fileType}
                        />
                    </div>
                    <ZoomButtons {...scale} className={styles.zoomButtons} />
                </div>
                <div className={styles.column}>
                    <div className={styles.columnInner}>
                        <PdfPage
                            pdfBlob={fileBlob.blob}
                            pageIndex={pageNumber}
                            isEditable={false}
                            isPreview={false}
                            redactions={redactions}
                            pdfWords={pdfFileData?.pdfWords}
                            newRedactionSource={PdfAndImageRedactionSource.AttachedToFile}
                            generatorSetup={dataset?.generatorSetup}
                            pdfScale={scale.scale}
                            fileType={file.fileType}
                        />
                    </div>
                    <ZoomButtons {...scale} className={styles.zoomButtons} />
                    <div className={styles.disclaimer}>
                        This preview provides a rough approximation of the results. The downloaded version of the file contains better, more accurate
                        results.
                    </div>
                </div>
            </>
        );
    }

    return null;
}, equal);
