import { QuiBox, QuiButton, QuiModalContent, QuiModalDialog, QuiModalFooter, QuiText, useQuiModal } from '@tonicai/ui-quinine';
import { useField, useForm } from 'react-final-form';

type BlockListFieldProps = Readonly<{
    name: string;
}>;

export function BlockListField({ name }: BlockListFieldProps) {
    const modal = useQuiModal();
    const form = useForm();

    const stringsField = useField<string[], HTMLTextAreaElement, string>(`${name}.strings`, {
        parse: (v) => {
            return v?.split('\n') ?? [];
        },
        format: (v) => {
            if (Array.isArray(v) && v.every((s) => typeof s === 'string')) {
                return v.join('\n');
            } else {
                return '';
            }
        },
    });

    const regexesField = useField<string[], HTMLTextAreaElement, string>(`${name}.regexes`, {
        parse: (v) => {
            return v?.split('\n') ?? [];
        },
        format: (v) => {
            if (Array.isArray(v) && v.every((s) => typeof s === 'string')) {
                return v.join('\n');
            } else {
                return '';
            }
        },
    });

    const totalBlockItems =
        (stringsField.input.value?.split('\n').filter((t) => t.trim().length > 0).length ?? 0) +
        (regexesField.input.value?.split('\n').filter((t) => t.trim().length > 0).length ?? 0);

    return (
        <>
            <QuiButton iconRight="slash" size="sm" variant="outline-default" onClick={modal.toggle}>
                <span>{totalBlockItems}</span>
            </QuiButton>

            <QuiModalDialog disableDismissOnEscapeKeyDown={false} isOpen={modal.isOpen} onClose={modal.close} title="Blocklist">
                <QuiModalContent>
                    <QuiBox display={'flex'} flexDirection={'row'} gap={'md'}>
                        <QuiBox display={'flex'} flexDirection={'column'} gap={'sm'}>
                            <QuiText weight={'bold'}>{'Keywords or Phrases'}</QuiText>
                            <QuiText size={'text-xs'} className={'qui-color-black-600'}>
                                {'One entry per line. Each entry can be a regex pattern you wish to exclude from redaction'}
                            </QuiText>
                            <textarea
                                className="qui-textarea"
                                placeholder={'Add specific terms...'}
                                style={{ minHeight: 'min(30vh, 100vh - 12rem)' }}
                                {...stringsField.input}
                            />
                        </QuiBox>
                        <QuiBox display={'flex'} flexDirection={'column'} gap={'sm'}>
                            <QuiText weight={'bold'}>{'Regexes'}</QuiText>
                            <QuiText size={'text-xs'} className={'qui-color-black-600'}>
                                {'One entry per line. Each entry can be a specific value you wish to exclude from redaction'}
                            </QuiText>
                            <textarea
                                className="qui-textarea"
                                placeholder={'Add regex...'}
                                style={{ minHeight: 'min(30vh, 100vh - 12rem)' }}
                                {...regexesField.input}
                            />
                        </QuiBox>
                    </QuiBox>
                </QuiModalContent>
                <QuiModalFooter>
                    <QuiBox display={'flex'} justifyContent={'end'} className={'qui-w-100'} gap={'sm'}>
                        <QuiButton type="button" onClick={modal.close}>
                            Cancel
                        </QuiButton>
                        <QuiButton
                            type="button"
                            variant="brand-purple"
                            onClick={() => {
                                form.submit()?.then(() => {
                                    modal.close();
                                });
                            }}
                        >
                            Save
                        </QuiButton>
                    </QuiBox>
                </QuiModalFooter>
            </QuiModalDialog>
        </>
    );
}
