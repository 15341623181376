import { QuiSelectField, QuiTextField } from '@tonicai/ui-quinine';
import { useIsSharepointUploadEnabled } from '../../stores/settings';
import { requiredString } from '../../validation';
import { CreateDialog, CreateDialogProps } from '../CreateDialog/CreateDialog';
import { isAxiosError } from 'axios';
import { FORM_ERROR } from 'final-form';
import { FileSource } from '../../types';
import { createDataset } from '../../stores/datasets';
import { useNavigate } from 'react-router-dom';

type CreateDatasetFormValues = Readonly<{
    name: string;
    fileSource: FileSource;
}>;

const initialValues: CreateDatasetFormValues = {
    name: '',
    fileSource: 'Local',
};

export function CreateDatasetDialog({ isOpen, onClose }: Readonly<Pick<CreateDialogProps<CreateDatasetFormValues>, 'onClose' | 'isOpen'>>) {
    const supportsSharepoint = useIsSharepointUploadEnabled();
    const navigate = useNavigate();

    return (
        <CreateDialog<CreateDatasetFormValues>
            isOpen={isOpen}
            onClose={onClose}
            initialValues={initialValues}
            itemName="Dataset"
            onSubmit={({ name, fileSource }: CreateDatasetFormValues) => {
                return createDataset(name, fileSource)
                    .then((datasetId) => {
                        navigate(`/datasets/${datasetId}`);
                    })
                    .catch((e) => {
                        if (isAxiosError(e) && e.response?.status === 409) {
                            return {
                                [FORM_ERROR]: "There's already a dataset with that name. Please choose another name.",
                            };
                        }

                        return {
                            [FORM_ERROR]: 'Something unexpected went wrong. Please try again.',
                        };
                    });
            }}
            description="A dataset is a set of files to redact."
        >
            <QuiTextField placeholder="Enter the name for the dataset..." validate={requiredString} name="name" label="Dataset Name" />

            {supportsSharepoint && (
                <QuiSelectField
                    label={'File Source'}
                    name={'fileSource'}
                    validate={requiredString}
                    options={[
                        { label: 'Local', value: 'Local' },
                        { label: 'Sharepoint', value: 'Sharepoint' },
                    ]}
                    menuPortalTarget={document.body}
                />
            )}
        </CreateDialog>
    );
}
