import { QuiIconToken } from '@tonicai/ui-quinine';
import { PiiTypeGeneratorState, PiiTypeEnum } from '.';

export const FILE_TYPES = ['Csv', 'Tsv', 'Raw', 'Pdf', 'DocX', 'Png', 'Jpg', 'Tif', 'Xlsx'] as const;

export type FileType = (typeof FILE_TYPES)[number];

export const FILE_TYPE_EXTENSIONS: Record<FileType, string[]> = {
    Csv: ['csv'],
    Tsv: ['tsv'],
    Raw: ['txt'],
    Pdf: ['pdf'],
    DocX: ['docx'],
    Png: ['png'],
    Jpg: ['jpg', 'jpeg'],
    Tif: ['tif', 'tiff'],
    Xlsx: ['xlsx'],
};

export const ALL_FILE_EXTENSIONS = Object.values(FILE_TYPE_EXTENSIONS).reduce((acc, curr) => acc.concat(curr), []);

export type FileSource = 'Local' | 'Sharepoint' | 'Aws';

export enum FileSourceStringEnum {
    Local = 'Local',
    Sharepoint = 'Sharepoint',
    Aws = 'Aws',
    Databricks = 'Databricks',
}

export enum FileSourceEnum {
    Local = 0,
    Sharepoint = 1,
    Aws = 2,
    Databricks = 3,
}

// Requests & Responses are inconsistent, so these are here to help switch them around
export const FILE_SOURCE_STRING_TO_INTEGER: Record<FileSourceStringEnum, FileSourceEnum> = {
    Aws: FileSourceEnum.Aws,
    Local: FileSourceEnum.Local,
    Sharepoint: FileSourceEnum.Sharepoint,
    Databricks: FileSourceEnum.Databricks,
};
export const FILE_SOURCE_INTEGER_TO_STRING: Record<FileSourceStringEnum, FileSourceEnum> = {
    Aws: FileSourceEnum.Aws,
    Local: FileSourceEnum.Local,
    Sharepoint: FileSourceEnum.Sharepoint,
    Databricks: FileSourceEnum.Databricks,
};

// Keep in sync with backend/Solar.Core/Helpers/JobStatuses.cs
export enum JobStatus {
    RUNNING = 'Running',
    QUEUED = 'Queued',
    CANCELED = 'Canceled',
    COMPLETED = 'Completed',
    FAILED = 'Failed',
    SKIPPED = 'Skipped',
}

export type PiiTextExample = {
    endIndex: number;
    startIndex: number;
    text: string;
};

export type LabelBlockList = {
    strings: string[];
    regexes: string[];
};

export enum ModelTransformation {
    TimestampShift = 'TimestampShift',
}

export enum StringDateFormat {
    YearMonthDayNoSeparator = 'yyyyMMdd',
    YearMonthDayHyphen = 'yyyy-MM-dd',
    DayMonthYearSlash = 'dd/MM/yyyy',
    DayMonthYearHyphen = 'dd-MM-yyyy',
    MonthDayYearSlash = 'MM/dd/yyyy',
    MonthDayShortYearSlash = 'MM/dd/yy',
    ShortMonthDayYearSlash = 'M/dd/yyyy',
    MonthDayYearHyphen = 'MM-dd-yyyy',
    MonthDayYearNoSeparator = 'MMddyyyy',
    DayThreeCharMonthYearSlash = 'dd/MMM/yyyy',
    DayThreeCharMonthYearHyphen = 'dd-MMM-yyyy',
    DayFullMonthyearSpace = 'dd MMMM yyyy',
    MonthYearHyphen = 'MM-yyyy',
}

export type HipaaAddressMetadata = {
    realisticSyntheticValues: boolean;
    replaceTruncatedZerosInZipCode: boolean;
};

export type TimestampShiftMetadata = {
    timestampShiftInDays?: number;
};

export type NameMetadata = {
    isConsistencyCaseSensitive: boolean;
};

export type DateTimeMetadata = {
    dateTimeTransformation?: ModelTransformation.TimestampShift;
    additionalDateFormats?: string[];
    scrambleUnrecognizedDates?: boolean;
    metadata?: TimestampShiftMetadata; //only option for now, could make it a union type down the line
};

export type DatasetGeneratorMetadata = {
    [PiiTypeEnum.DATE_TIME]: DateTimeMetadata;
    [PiiTypeEnum.LOCATION]: HipaaAddressMetadata;
    [PiiTypeEnum.PERSON]: NameMetadata;
};

export type DatasetFile = {
    fileId: string;
    fileName: string;
    datasetId: string;
    numColumns: number;
    piiTypes: string[];
    piiTypeCounts: Record<string, number>;
    piiTypeExamples: Record<string, PiiTextExample[]>;
    numRows?: number;
    uploadedTimestamp: string;
    wordCount?: number;
    redactedWordCount: number;
    processingStatus: JobStatus;
    processingError?: string;
    fileType: FileType;
    fileSource: FileSource;
    mostRecentCompletedJobId?: string;
};

export type Dataset = {
    name: string;
    id: string;
    files: DatasetFile[];
    generatorSetup: Record<string, PiiTypeGeneratorState>;
    datasetGeneratorMetadata: Partial<DatasetGeneratorMetadata>;
    labelBlockLists: Record<string, LabelBlockList>;
    enabledModels: string[];
    lastUpdated: string;
    fileSource: FileSource;
};

export type GeneratorMetadataProps = Readonly<{
    name: string;
    piiType: string;
    automaticallySubmit: boolean;
}>;

export type DatasetFormState = {
    id: string;
    name: string;
    generatorSetup: Record<string, PiiTypeGeneratorState>;
    labelBlockLists: Record<string, LabelBlockList>;
    enabledModels: string[];
    datasetGeneratorMetadata: Partial<DatasetGeneratorMetadata>;
};

export type GeneratorSetupFormState = {
    generatorSetup: Record<PiiTypeEnum, PiiTypeGeneratorState>;
};

export type DeidentifyResponse = {
    start: number;
    end: number;
    label: string;
    text: string;
    score: number;
    ordinalPosition: number;
    exampleRedaction?: string;
};

export type DeidentifyPreview = {
    ordinalPosition: number;
    rowNum: number;
    deIdentifyResult: DeidentifyResponse[];
};

export type RedactedTextChunk = {
    id: string;
    text: string;
    label?: string;
    score?: number;
    exampleRedaction?: string;
};

export type PreviewColumnData = {
    id: string;
    ordinalPosition: number;
    chunks: RedactedTextChunk[];
};

export type PiiTypeInfo = {
    label: string;
    icon: QuiIconToken;
    description: string;
};

export const FILE_TYPE_MIME_TYPES: Record<FileType, string[]> = {
    Csv: ['text/csv'],
    Raw: ['text/plain'],
    Pdf: ['application/pdf'],
    DocX: ['application/vnd.openxmlformats-officedocument.wordprocessingml.document'],
    Png: ['image/png'],
    Jpg: ['image/jpeg'],
    Tif: ['image/tiff'],
    Tsv: ['text/tab-separated-values'],
    Xlsx: ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'],
};

export const FILE_TYPES_THAT_DONT_SUPPORT_PREVIEW: FileType[] = ['Tif', 'Xlsx'];

export const ACCEPTED_MIME_TYPES = Object.values(FILE_TYPE_MIME_TYPES)
    .reduce((acc, curr) => acc.concat(curr), [])
    .join(', ');
