import { Dataset, DatasetFormState, JobStatus, PiiTypeGeneratorState } from '../../types';
import { QuiBox, QuiButton, QuiFlexBoxColumn, QuiIcon, QuiIconEnum, QuiLink, QuiText } from '@tonicai/ui-quinine';
import { RedactionTypes } from './RedactionTypes';
import { getPercentRedacted, getTotalRedactionsWithUnits } from './utils';
import { TEXTUAL_DOCUMENTATION_URL } from '../../constants';
import { useCallback, useMemo } from 'react';
import { FileAdder } from '../../components/FileAdder/FileAdder';
import { useForm, useFormState } from 'react-final-form';
import { StartChatButton } from '../../components/StartChatButton/StartChatButton';
import './GeneratorSetup.scss';

type DatasetSettingsProps = Readonly<{
    dataset: Dataset;
}>;

export function GeneratorSetup({ dataset }: DatasetSettingsProps) {
    const form = useForm<DatasetFormState>();
    const { submitting } = useFormState({ subscription: { submitting: true } });
    const keys = [...new Set([...dataset.files.flatMap((f) => f.piiTypes)])].sort();
    const typeOrTypes = keys.length === 1 ? 'Type' : 'Types';

    const hasFileProcessing = useMemo(() => {
        return dataset.files.some((f) => f.processingStatus === JobStatus.QUEUED || f.processingStatus === JobStatus.RUNNING);
    }, [dataset.files]);

    const bulkChangeGenerators = useCallback(
        (newGeneratorState: PiiTypeGeneratorState) => {
            form.batch(() => {
                const keys = Object.keys(form.getState().values?.['generatorSetup'] ?? {});
                keys.forEach((key) => {
                    form.change(`generatorSetup.${key}` as keyof DatasetFormState, newGeneratorState);
                });
            });
            form.submit();
        },
        [form]
    );

    return (
        <QuiBox display="flex" gap="md" flexDirection="column">
            <QuiBox display="flex" gap="md">
                <QuiBox bg="white" borderRadius="md" padding="md" display="flex" flexDirection="column" gap="sm" border="white-600">
                    <span className={'redaction-box-title'}>Sensitive words</span>
                    <span className={'redaction-box-count'}>{getTotalRedactionsWithUnits(dataset)}</span>
                    <span className={'redaction-box-percent'}>{getPercentRedacted(dataset)}% of Scanned Data</span>
                </QuiBox>
                <QuiBox
                    borderRadius="md"
                    padding="md"
                    display="flex"
                    flexDirection="row"
                    gap="sm"
                    border="white-600"
                    alignItems="center"
                    flexGrow="1"
                    bg="white"
                >
                    <QuiBox display="flex" gap="sm" alignItems="start">
                        <div>
                            <QuiIcon icon={QuiIconEnum.LifeBuoy} />
                        </div>
                        <div>
                            <QuiFlexBoxColumn>
                                <QuiBox text="text-md" weight="medium">
                                    How to redact sensitive data
                                </QuiBox>
                                <QuiBox text="text-sm" color="text-secondary">
                                    <QuiLink external variant="currentcolor" iconRight="external-link" to="https://docs.tonic.ai/textual">
                                        View documentation
                                    </QuiLink>
                                    &nbsp;
                                    <StartChatButton />
                                </QuiBox>
                            </QuiFlexBoxColumn>
                        </div>
                    </QuiBox>
                </QuiBox>
            </QuiBox>

            {hasFileProcessing && (
                <QuiBox bg="white-200" gap="md" display="flex" flexDirection="column" borderRadius="md" padding="lg">
                    <QuiBox color="black-900">
                        <QuiIcon icon="refresh-cw" />
                    </QuiBox>
                    <QuiBox text="text-sm" color="black-900" weight="medium">
                        Scanning file for sensitive data types...
                    </QuiBox>
                    <QuiBox color="black-600" text="text-xs">
                        This might take up to 3-5 minutes, depending on the file sizes.
                    </QuiBox>
                </QuiBox>
            )}

            {dataset.files.length > 0 && (
                <QuiBox
                    alignItems="center"
                    bg="background-primary"
                    border="border-default"
                    padding="md"
                    borderRadius="md"
                    display="flex"
                    justifyContent="space-between"
                >
                    <QuiText>Bulk Edit</QuiText>
                    <QuiBox display="flex" gap="sm">
                        <QuiButton
                            disabled={submitting}
                            size="sm"
                            onClick={() => {
                                bulkChangeGenerators('Synthesis');
                            }}
                            type="button"
                        >
                            Synthesize
                        </QuiButton>
                        <QuiButton
                            disabled={submitting}
                            size="sm"
                            onClick={() => {
                                bulkChangeGenerators('Redaction');
                            }}
                            type="button"
                        >
                            Redact
                        </QuiButton>
                        <QuiButton
                            disabled={submitting}
                            size="sm"
                            onClick={() => {
                                bulkChangeGenerators('Off');
                            }}
                            type="button"
                        >
                            Off
                        </QuiButton>
                    </QuiBox>
                </QuiBox>
            )}

            {dataset.files.length === 0 ? (
                <QuiBox display="flex" flexDirection="column" gap="md" bg="white-200" borderRadius="md" padding="lg">
                    <QuiBox weight="medium">No files found...</QuiBox>
                    <QuiBox>
                        To get started, upload files to redact. Unstructured free-text files, such as patient notes or customer call logs, work best.{' '}
                        <QuiLink variant="currentcolor" external iconRight="external-link" to={TEXTUAL_DOCUMENTATION_URL}>
                            View documentation
                        </QuiLink>
                    </QuiBox>
                    <QuiBox>
                        <FileAdder name="fileIds" datasetId={dataset.id} mode="button" />
                    </QuiBox>
                </QuiBox>
            ) : (
                <QuiBox display="flex" flexDirection="column" gap="xs">
                    <QuiBox display="flex" gap="sm" flexDirection="row" justifyContent="space-between">
                        <span className={'sensitive-count'}>
                            {keys.length} Sensitive Data {typeOrTypes} Found
                        </span>
                        <span className={'redaction-count'}>
                            Redacting {Object.values(dataset.generatorSetup).filter((f) => f !== 'Off').length} of {keys.length}
                        </span>
                    </QuiBox>

                    <RedactionTypes keys={keys} dataset={dataset} />
                </QuiBox>
            )}
        </QuiBox>
    );
}
